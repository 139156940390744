/* eslint-disable prefer-destructuring */
/* eslint-disable lit/no-invalid-html */
import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/no-extraneous-dependencies

import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { getCurrentApi } from '@kisters/wiski-web/api';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { customElement, property } from 'lit/decorators.js';

@customElement('ww-hdoe-hydrochar')
export default class wwHdoeHydrochar extends LitElement {
  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    .msg {
      margin: auto;
    }

    ui5-select {
      width: 300px;
    }
  `;

  months = [
    'Jahrescharakteristik',
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  api = getCurrentApi();

  docs: Array<string> = [];

  @property({ type: String })
  path = '/documents';

  loading = true;

  selectedYear = '';

  @property({ type: String })
  selectedDoc = '';

  async onAfterEnter() {
    this.fetchData();
  }

  _getFullPath(file) {
    const folder =
      file.length === 4 ? 'charakteristikJahr' : 'CharakteristikMonat';
    return new URL(
      `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.api.dataPath}${this.path}/${folder}/${file}.pdf`,
    ).href;
  }

  async fetchData() {
    const year = await this.api._requestJson(
      `${this.path}/charakteristikJahr/index.json`,
    );
    const month = await this.api._requestJson(
      `${this.path}/CharakteristikMonat/index.json`,
    );
    this.loading = false;
    const yearDocs = year?.data?.map(item => item.name.substring(0, 4)) || [];
    const monthDocs = month?.data?.map(item => item.name.substring(0, 7)) || [];
    this.docs = yearDocs.concat(monthDocs).sort().reverse();
    this.selectedDoc = this.docs.length
      ? this._getFullPath(monthDocs[monthDocs.length - 1])
      : 'leer';
  }

  getMonthName(chr) {
    const month = chr.substring(5, 7) ? parseInt(chr.substring(5, 7), 10) : 0;
    return `${chr.substring(0, 4)} ${this.months[month]}`;
  }

  render() {
    // language=html
    if (this.loading) {
      return html`<div class="msg">Berichte werden geladen...</div>`;
    }
    return this.docs.length
      ? html`<div class="left-panel">
            <ui5-select
              value="${this.docs[0]}"
              @change="${e => {
                this.selectedDoc = `${this._getFullPath(
                  e.target.selectedOption.value,
                )}`;
              }}"
            >
              ${this.docs.map(
                item =>
                  html`<ui5-option value="${item}"
                    >${this.getMonthName(item)}</ui5-option
                  >`,
              )}
            </ui5-select>

            <a
              href="https://www2.land-oberoesterreich.gv.at/internethydro/Monat/Start.jsp"
              target="_new"
              >Archivdaten</a
            >
          </div>
          <div class="right-panel">
            <iframe
              title="content"
              id="contentframe"
              src="./pdfjs/web/viewer.html?file=${this.selectedDoc}"
            ></iframe>
          </div>`
      : html`<div class="msg">Aktuell sind keine Berichte vorhanden</div>`;
  }
}
