// eslint-disable-next-line max-classes-per-file
import { customElement } from 'lit/decorators.js';
import { html, LitElement, css } from 'lit';
import sparkline from '@fnando/sparkline/src/sparkline';
import dayjs from '@kisters/wcp-base/common/dayjsext';

@customElement('ki-wwp-sparkline')
export class KiWwpSparkline extends LitElement {
  data = [];

  static get properties() {
    return {
      data: { type: Array },
    };
  }

  static styles = css`
    *[hidden] {
      display: none;
    }

    .tooltip {
      position: fixed;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      padding: 2px 5px;
      font-size: 12px;
      white-space: nowrap;
      z-index: 9999;
    }

    .sparkline--cursor {
      stroke: orange;
    }

    .sparkline--spot {
      fill: red;
      stroke: red;
    }
  `;

  // eslint-disable-next-line class-methods-use-this
  _findClosest(target: { tagName: any; parentNode: any }, tagName: any) {
    if (target.tagName === tagName) {
      return target;
    }

    // eslint-disable-next-line no-cond-assign
    while ((target = target.parentNode)) {
      if (target.tagName === tagName) {
        break;
      }
    }

    return target;
  }

  protected firstUpdated(): void {
    const findClosest = (target, tagName) => {
      if (target.tagName === tagName) {
        return target;
      }

      // eslint-disable-next-line no-cond-assign
      while ((target = target.parentNode)) {
        if (target.tagName === tagName) {
          break;
        }
      }

      return target;
    };

    const options = {
      onmousemove(event, datapoint) {
        const svg = findClosest(event.target, 'svg');
        const tooltip = svg.nextElementSibling;
        const date = dayjs(datapoint.date).tz().format('L LT');

        tooltip.hidden = false;
        tooltip.textContent = `${date}: ${datapoint.value} ${datapoint.unit}`;
        tooltip.style.top = `${event.clientY}px`;
        tooltip.style.left = `${event.clientX + 20}px`;
      },

      onmouseout(event) {
        const svg = findClosest(event.target, 'svg');
        const tooltip = svg.nextElementSibling;

        tooltip.hidden = true;
      },
    };
    sparkline(this.renderRoot.querySelector('#sparkline'), this.data, options);
  }

  protected render(): unknown {
    return html`<svg
        id="sparkline"
        width="100"
        height="30"
        stroke-width="2"
        stroke="blue"
        fill="rgba(0, 0, 255, .2)"
      ></svg
      ><span class="tooltip" hidden="true"></span>`;
  }
}
