import FontSymbol from 'ol-ext/style/FontSymbol';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import olStyleImage from 'ol/style/Image';

/*
 * must ensure font css loaded before use it.
 * */

const tmpCanvas = document.createElement('CANVAS');
const tmpCanvasCtx = tmpCanvas.getContext('2d');

olStyleImage.prototype.getImagePNGLegend = function () {
  const canvas = this.getImage(1);
  if (canvas) {
    try {
      tmpCanvasCtx.restore();
      tmpCanvas.width = canvas.width;
      tmpCanvas.height = canvas.height;
      if (this.getRotation()) {
        const half = canvas.width / 2;
        tmpCanvasCtx.translate(half, half);
        tmpCanvasCtx.rotate(this.getRotation());
        tmpCanvasCtx.drawImage(canvas, half * -1, half * -1);
        return tmpCanvas.toDataURL('image/png');
      }

      tmpCanvasCtx.drawImage(canvas, 0, 0);
      return tmpCanvas.toDataURL('image/png');
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};
export class FontIcon extends FontSymbol {
  constructor({
    iconTemplate = '',
    iconTemplateDecimals = 0,
    icon = 'ki ki-info-circle',
    color = 'darkblue',
    shape = 'circle',
    radius = 10,
    rotation = 0,
    rotateWithView = false,
    backgroundColor,
    border /* color,width */,
    fontSize = 1,
  } = {}) {
    let glyph;
    let iconStyle;
    if (icon.length > 3) {
      const el = document.createElement('i');
      el.className = icon;
      el.setAttribute('style', 'display:none');
      document.body.append(el);
      const cs = window.getComputedStyle(el, ':before');
      iconStyle = {
        content: cs.getPropertyValue('content'),
        fontFamily: cs.getPropertyValue('font-family'),
        fontWeight: cs.getPropertyValue('font-weight'),
        fontSize: cs.getPropertyValue('font-size'),
        backgroundColor: 'rgb(0,255,0)',
      };
      if (iconStyle.content.length === 3) {
        iconStyle.content = iconStyle.content.charAt(1);
      } else if (iconStyle.content.length === 1) {
        // safari browser
        iconStyle.content = iconStyle.content.charAt(0);
      }
      document.body.removeChild(el);
      glyph = {
        char: iconStyle.content,
        font: iconStyle.fontFamily,
        theme: 'none',
        name: 'none',
        search: '',
      };
    } else {
      glyph = {
        char: icon,
        font: 'Arial',
        theme: 'none',
        name: 'none',
        search: '',
        iconTemplate,
        iconTemplateDecimals,
      };
    }
    super({
      form: shape,
      gradient: false,
      glyph,
      fontSize, // related to radius
      fontStyle: iconStyle ? iconStyle.fontWeight : 900, // need for font awesome
      radius,
      rotation,
      rotateWithView,
      offsetY: 0,
      color,
      fill: backgroundColor && new Fill({ color: backgroundColor }),
      stroke: border && new Stroke(border),
    });
  }

  getGlyph(name) {
    if (typeof name === 'object') {
      return name;
    }
    return super.getGlyph(name);
  }

  getImagePNGLegend = olStyleImage.prototype.getImagePNGLegend;
}
