import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import MapPositionScaleLayer from './MapPositionScaleLayer';

@customElement('ki-station-map-position-scale-control')
export default class kiStationMapPositionScaleControl extends _AddOn {
  constructor() {
    super();
    this.displayInLayerControl = false;
  }

  static get properties() {
    return {
      label: { type: String },
      displayInLayerControl: { type: Boolean },
    };
  }

  _create() {
    if (this.projections) {
      this.mapPositionScaleLayer = new MapPositionScaleLayer({
        name: 'Station position',
        displayInLayerControl: this.displayInLayerControl,
        group: 'Information',
        label: this.label,
        projections: this.projections,
        selectedStations: this.selectedStations,
        map: this.map,
      });
      this.map.addControl(this.mapPositionScaleLayer);
    }
  }

  _tear() {
    this.map.removeControl(this.mapPositionScaleLayer);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
