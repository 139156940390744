/* eslint-disable camelcase */
import { KiwisConfig } from './KiwisConfig';

export default class KiwisHelper {
  /** Detect and convert relative path to absolute path
   * @param windowLocation is required if a realtive path is supplied
   */
  public static resolveUrl(
    kiwisCfg: KiwisConfig,
    windowLocation?: Object,
  ): string {
    /** Catch absolute path */
    if (
      kiwisCfg.basePath &&
      kiwisCfg.basePath.includes('//') &&
      !kiwisCfg.basePath.startsWith('//')
    )
      return kiwisCfg.basePath;
    /** Build from relative path and location */
    if (kiwisCfg.basePath && windowLocation) {
      return this._buildAbsPath(kiwisCfg.basePath, windowLocation);
    }
    throw Error("Can't build kiwis path");
  }

  static _buildAbsPath(relPath, loc): string {
    /** Absolute path without specified protocol (http and https) */
    if (relPath.startsWith('//')) return `${loc.protocol}${relPath}`;
    /** Relative path handling */
    if (relPath.startsWith('/')) return `${loc.origin}${relPath}`;
    return `${loc.origin}/${relPath}`;
  }

  public static buildTsPath(station, ts_shortname) {
    if (ts_shortname)
      return `${station.site_no}/${station.station_no}/${station.stationparameter_no}/${ts_shortname}`;
    return `${station.site_no}/${station.station_no}/${station.stationparameter_no}/${station.ts_shortname}`;
  }
}
