import dayjs from '@kisters/wcp-base/common/dayjsext';

export default class PeriodsList {
  allowtime;

  constraints;

  _periods;

  _periodsWithoutTime;

  periodListOptions;

  value;

  constructor(classification) {
    this.constraints =
      classification && classification.constraints
        ? classification.constraints
        : {};

    const options =
      classification && classification.periods
        ? classification.periods
        : [
            [
              'today',
              'thisWeek',
              'thisMonth',
              'thisYear',
              'theDaySoFar',
              'last15day',
              'last30day',
              'last60day',
              'last6month',
              'last1year',
              'last2year',
            ],
          ];
    this.allowtime === true
      ? (this.periods = options)
      : (this.periodsWithoutTime = options);
    this.periodListOptions =
      classification && classification.periodListOptions
        ? classification.periodListOptions
        : null;
    this.value = 'today';
  }

  set periods(val) {
    if (!val) {
      return;
    }
    this._periods = val;
  }

  get periods() {
    return this._periodss;
  }

  set periodsWithoutTime(val) {
    if (!val) {
      return;
    }
    this._periodsWithoutTime = val;
  }

  get periodsWithoutTime() {
    return this._periodsWithoutTime;
  }

  static getDurationBySrt(periodStr) {
    let matched;
    let m;
    let unit;
    switch (periodStr) {
      case 'custom':
        return {
          from: dayjs(),
          to: dayjs(),
        };
      case 'today':
        return {
          from: dayjs().startOf('day'),
          to: dayjs().add(1, 'days').startOf('day'),
        };
      case 'thisWeek':
        return {
          from: dayjs().startOf('week'),
          to: dayjs().add(1, 'weeks').startOf('week'),
        };
      case 'thisMonth':
        return {
          from: dayjs().startOf('month'),
          to: dayjs().add(1, 'months').startOf('month'),
        };
      case 'lastMonth':
        return {
          from: dayjs().add(-1, 'months'),
          to: dayjs(),
        };
      case 'thisYear':
        return {
          from: dayjs().startOf('year'),
          to: dayjs().add(1, 'years').startOf('year'),
        };
      case 'lastYear':
        return {
          from: dayjs().add(-1, 'years'),
          to: dayjs(),
        };
      case 'theDaySoFar':
        return {
          from: dayjs().startOf('day'),
          to: dayjs(),
        };
      case 'WTD':
        return {
          from: dayjs().startOf('week'),
          to: dayjs().startOf('day'),
        };
      case 'MTD':
        return {
          from: dayjs().startOf('month'),
          to: dayjs().startOf('day'),
        };
      case 'YTD':
        return {
          from: dayjs().startOf('year'),
          to: dayjs().startOf('day'),
        };
      case 'yesterday':
        m = dayjs().subtract(1, 'days');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'dayBeforeYesterday':
        m = dayjs().subtract(2, 'days');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'dayLastWeek':
        m = dayjs().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'preWeek':
        m = dayjs().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('week'),
          to: m.add(1, 'weeks').startOf('week'),
        };
      case 'preMonth':
        m = dayjs().subtract(1, 'months');
        return {
          from: m.clone().startOf('month'),
          to: m.add(1, 'months').startOf('month'),
        };
      case 'preYear':
        m = dayjs().subtract(1, 'years');
        return {
          from: m.clone().startOf('year'),
          to: m.add(1, 'years').startOf('year'),
        };
      case 'periodOfRecord':
        return {
          from: dayjs().startOf('year'),
          to: dayjs().startOf('day'),
        };
      default:
        matched = periodStr.match(/^last(\d+)(min|hour|day|month|year)/i);
        if (matched) {
          m = dayjs();
          unit = matched[2].toLowerCase();
          if (unit === 'min') {
            unit = 'minute';
          }
          return {
            from: m.clone().subtract(matched[1], `${unit}s`),
            to: m,
          };
        }
        return {};
    }
  }

  getPeriodsList() {
    const returnOptions = [];
    const options =
      this.allowtime === true ? this.periods : this.periodsWithoutTime;
    options.forEach(col => {
      const returnCol = [];
      col.forEach(opt => {
        returnCol.push({
          label: opt,
          dates: PeriodsList.getDurationBySrt(opt),
        });
      });
      returnOptions.push(returnCol);
    });
    return returnOptions;
  }
}
