import { css } from 'lit';

// language=CSS
export default css`
  :host {
    display: block;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .header {
    flex: 0 0 50px;
    padding: 0 20px;
    line-height: 20px;
    font-size: 0.8em;
    color: gray;
    max-height: 100px;
    min-height: 35px;
    border-bottom: 1px solid lightgray;
    display: flex;
  }

  .title {
    font-size: 1.5em;
    line-height: 50px;
  }

  .header-center {
    flex: 1;
  }

  .customAttributes {
    margin-top: 15px;
    font-size: 1.25em;
  }

  .adminStatus {
    color: red;
    font-weight: bold;
  }

  .adminBemerkung {
    margin-left: 5px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .containerNode {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: row;
  }

  ki-icon-btn {
    margin: 5px;
    box-shadow: none;
  }

  ki-icon-btn.selected {
    fill: white;
    background: var(--theme-color-secondary, #0056a0);
  }

  .chart-wrapper {
    flex: 1;
    overflow: hidden;
  }

  .table-wrapper {
    flex: 0 30%;
    border-left: lightgray 1px solid;
    height: 100%;
    overflow: auto;
  }

  .hidden {
    display: none;
  }

  ki-ts-graph {
    height: 100%;
  }

  .header .separator {
    font-size: 1.2em;
    font-weight: 600;
    padding: 0px 0.5em;
    color: rgb(204, 204, 204);
    line-height: 50px;
  }

  ki-time-range-picker {
    height: 50px;
  }
`;
