/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { getQueryObject } from '../../decorators/query';
import './ki-progress-bar';

// eslint-disable-next-line no-shadow
export enum legendType {
  legendEntries,
  minMax,
}

export interface LegendEntryChild {
  label: string;
  untilPercentage: number;
}

export interface LegendEntry {
  labelPosition: string;
  label: string;
  untilPercentage: number;
  children: Array<LegendEntryChild>;
}

export interface MinMaxConfig {
  minLabel: string;
  maxLabel: string;
  children: Array<LegendEntryChild>;
}

@customElement('ki-progress-bar-legend')
export default class KiProgressBarLegend extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      display: block;
    }
    .legend-container {
      overflow: hidden;
      width: 100%;
      height: 24px;
    }
    .left-border {
      border-left: 1px solid black;
    }
    .legend-entry {
      overflow: hidden;
      cursor: pointer;
      padding-left: 5px;
      height: 100%;
      float: left;
      clear: none;
    }
    .min-max-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
    }
    .min-max-item {
      margin-top: 5px;
    }
    .min-max-item-left {
      margin-left: 5px;
    }
    .min-max-item-center {
      flex: 1;
    }
    .min-max-item-right {
      margin-right: 5px;
    }
  `;

  /**
   * Auto show popup in progess.
   *
   * @type {number}
   */
  @property({ type: Boolean, attribute: true })
  forcePopup: Boolean = false;

  /**
   * Percentage steps on right/left arrow key.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  keyDownSteps: number = 5;

  /**
   * Width of the legend popup.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  popupWidth: number = 140;

  /**
   * Current status.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  statusPercentage: number = 0;

  /**
   * The legend entries. Each entry has a label and a max percentage.
   * Children are the entries shown on hover. The `untilPercentage` of each child is relative to its parent.
   *
   * @type {Array<LegendEntry>}
   */
  @property({ type: Array, attribute: true })
  legendEntries: Array<LegendEntry> = [];

  /**
   * The type of legend. (legendEntries, minMax)
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  legendType: legendType = legendType.legendEntries;

  /**
   * The configuration for the legend. Consists of label for min and max value.
   * Children are the entries shown on hover. The `untilPercentage` of each child is relative to the whole bar.
   *
   * @type {{ minLabel: string; maxLabel: string, children: Array<{ label: string; untilPercentage: number}> }}
   */
  @property({ type: Object, attribute: true })
  minMaxConfig: MinMaxConfig | undefined;

  getPopupItems() {
    switch (this.legendType) {
      default:
      case legendType.legendEntries:
        return this.getPopupItemsFromLegendEntries();
      case legendType.minMax:
        return this.minMaxConfig?.children ?? [];
    }
  }

  getPopupItemsFromLegendEntries() {
    const popupItems: Array<{ label: string; untilPercentage: number }> = [];
    let lastPercentage = 0;
    this.legendEntries.forEach(entry => {
      const percentageRange = entry.untilPercentage - lastPercentage;
      entry.children.forEach(child => {
        const absolutePercentage =
          lastPercentage + (percentageRange * child.untilPercentage) / 100;
        const { label } = child;

        popupItems.push({
          label,
          untilPercentage: absolutePercentage,
        });
      });

      if (
        popupItems.length === 0 ||
        popupItems[popupItems.length - 1].untilPercentage <
          entry.untilPercentage
      ) {
        popupItems.push({
          label: entry.label,
          untilPercentage: entry.untilPercentage,
        });
      }

      lastPercentage = entry.untilPercentage;
    });

    return popupItems;
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (): void => {
    this.requestUpdate();
  };

  jumpToPercentage(percentage): void {
    this.updatePercentage(percentage);
  }

  emitProgressChange(e): void {
    this.updatePercentage(e.detail.newPercentage);
  }

  updatePercentage(percentage) {
    let newPercentage = percentage;
    if (newPercentage < 0) {
      newPercentage = 0;
    }
    if (newPercentage > 100) {
      newPercentage = 100;
    }
    this.statusPercentage = newPercentage;
    this.dispatchEvent(
      new CustomEvent('progressChange', {
        detail: { newPercentage },
        bubbles: true,
        composed: true,
      }),
    );
  }

  renderLegend() {
    const maxWidth = getQueryObject(this, '#progress-bar')?.offsetWidth;

    switch (this.legendType) {
      default:
      case legendType.legendEntries:
        return this.renderEntries(maxWidth);
      case legendType.minMax:
        return this.renderMinMax();
    }
  }

  renderLegendEntry(
    entry: { label: string; untilPercentage: number; labelPosition: string },
    firstEntry: boolean,
    lastEntry: boolean,
    lastPercentage: number | undefined,
    maxWidth: number,
  ) {
    const lastPercentageNumber = lastPercentage ?? 0;
    const newWidth =
      ((entry.untilPercentage - lastPercentageNumber) * maxWidth) / 100 -
      (firstEntry ? 5 : 6) -
      (lastEntry ? 1 : 0);

    return html`
      <div
        class="legend-entry ${firstEntry ? '' : 'left-border'}"
        .style="width: ${newWidth}px;text-align: ${entry.labelPosition ||
        'left'}"
        @click=${() => {
          this.jumpToPercentage(lastPercentageNumber);
        }}
      >
        ${entry.label}
      </div>
    `;
  }

  renderEntries(maxWidth) {
    let lastItem: { label: string; untilPercentage: number };
    return this.legendEntries.map((entry, index) => {
      const lastPercentage = lastItem?.untilPercentage;
      const last = index === this.legendEntries.length - 1;
      lastItem = entry;
      return this.renderLegendEntry(
        entry,
        lastPercentage === undefined,
        last,
        lastPercentage,
        maxWidth,
      );
    });
  }

  renderMinMax() {
    if (!this.minMaxConfig) {
      return html``;
    }
    return html` <div class="min-max-container">
      <div class="min-max-item min-max-item-left">
        ${this.minMaxConfig.minLabel}
      </div>
      <div class="min-max-item min-max-item-center"></div>
      <div class="min-max-item min-max-item-right">
        ${this.minMaxConfig.maxLabel}
      </div>
    </div>`;
  }

  protected firstUpdated(): void {
    this.requestUpdate();
  }

  render() {
    return html`
      <ki-progress-bar
        id="progress-bar"
        .keyDownSteps="${this.keyDownSteps}"
        .statusPercentage="${this.statusPercentage}"
        .popupItems="${this.getPopupItems()}"
        .forcePopup="${this.forcePopup}"
        .popupWidth="${this.popupWidth}"
        @progressChange="${this.emitProgressChange}"
      ></ki-progress-bar>
      <div class="legend-container">${this.renderLegend()}</div>
    `;
  }
}
