import { css } from 'lit';
// language=CSS
export default css`
  :host {
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: 0 170px 0px 175px;
    position: relative;
    background: rgb(232, 232, 234);
  }
  ki-station-map {
    position: absolute;
    top: 30px;
    bottom: 0;
    left: 0;
    right: 0;
    width: initial;
    height: initial;
  }

  .top{
    margin: 5px 0;
    z-index:2;
  }
  .top iframe {
    flex: 1;
  }
  .bottom{
    z-index:2;
    flex: 2;
    display: flex;
    padding-bottom: 5px;
  }
  .content {
    background: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(97, 102, 113);
  }
  .bottom .content.left {
    flex: 2;
    position: relative;
    margin-right: 5px;
  }
  iframe {
    flex: 1;
    border: 0;
  }

  .top img {

    align-self: center;
    margin: 10px auto;
  }

  .titleNode {
    color: #fff;
    font-size: larger;
    background-color: #616671;
    padding-left: 10px;
  }



  .overlay{
    position: absolute;
    z-index: 11;
    top: 30px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .leftBorder, .rightBorder {
    background: #e8e8ea;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 170px;
  }

  .leftBorder {
    left: 0;
    width: 175px;
  }

  .rightBorder {
    right: 0;
  }

  @media only screen and (max-width: 1300px) {
    :host {
      padding: 0 10px;
      background: rgb(232, 232, 234);
    }
    .leftBorder, .rightBorder {
      display: none;
    }
    .bottom .content.left {
      margin-right: 10px;
    }
    div.top img {
      align-self: center;
      margin: 10px auto;
    }
  }
  @media only screen and (max-width: 860px) {
    :host {
      padding: 0 10px;
      background: rgb(232, 232, 234);
    }
    .bottom {
      flex-direction: column;
    }
    .bottom .content{
      margin-top:10px;
      display: block;
    }
    .bottom .content.left {
      margin-right: 0;
      min-height: 250pt;
    }
    div.top img {
      align-self: center;
      margin: auto;
      margin-bottom: 10px;
    }
  }
  :host(.sm-screen) .top .content{
    max-height: calc(100vh - 180px);
 
  }
  :host(.sm-screen) .top .content {
    overflow: auto;
  }
`;
