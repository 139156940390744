/* eslint-disable max-classes-per-file */
import {
  Mix,
  LoaderMixin,
  PropertyDefaultValue,
} from '@kisters/wcp-base/common';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { getCurrentApi } from '../../api/API';
import nls from '../../locales/index';

@customElement('wwp-html')
export default class WwpHtml extends Mix(
  LitElement,
  LoaderMixin,
  PropertyDefaultValue,
  [i18nMixin, { nls }],
) {
  api = getCurrentApi();

  static styles = css`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
    }
    iframe {
      flex: 1;
      width: 100%;
      height: 100%;
      border: none;
    }
    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  @property({ type: String })
  content;

  @property({ type: String })
  type;

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);

    this.content = params.content ?? params.options?.content;
    const { contentFolder } = params.options;
    this.contentFolder =
      contentFolder === undefined ? 'html' : params.options.contentFolder;
    this.type = params.options.type || 'html';
    this.basePath = params.options.basePath || './';
  }

  renderHTML() {
    return html`<iframe
      title="content"
      src="${this.basePath}${this.contentFolder}/${this.content}.${this.type}"
    ></iframe>`;
  }

  /* eslint-disable lit/no-invalid-html */
  renderPDF() {
    this.basePath = this.basePath || '';
    this.contentFolder = this.contentFolder || '';

    const fullurl = new URL(
      `${this.basePath}${this.contentFolder}/${this.content}.${this.type}`,
      window.location.origin,
    ).href;
    return html`<iframe
      title="pdf"
      src="./pdfjs/web/viewer.html?file=${fullurl}"
    ></iframe>`;
  }
  /* eslint-enable lit/no-invalid-html */

  render() {
    // language=html
    return this.type === 'pdf' ? this.renderPDF() : this.renderHTML();
  }
}
