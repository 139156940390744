import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { responsiveMixin, SM, ViewPort } from '@kisters/wcp-base/decorators';
import flatten from 'lodash-es/flatten';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

@customElement('ki-legend')
export default class KiLegend extends Mix(LitElement, responsiveMixin) {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      position: absolute;
      pointer-events: all;
    }

    :host(.sm-screen) {
      box-shadow: none;
    }

    :host(.md-screen) div.legends-list {
      background-color: var(
        --ki-legend-background-mdscreen,
        rgba(255, 255, 255, 1)
      );
    }

    :host(.hide) .legends-list {
      visibility: hidden;
      transform: scale(0.8);
      transform-origin: left bottom;
      opacity: 0;
    }

    .legends-list {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      transition: transform 0.3s linear 0s, opacity 0.3s linear 0s,
        visibility 0.3s linear 0s;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
    a {
      text-decoration: none;
    }

    .name,
    .item {
      padding: 5px 20px;
      color: #4a4a49;
    }

    .name {
      font-weight: 600;
      cursor: pointer;
    }

    @media screen and (max-height: 800px) {
      .name,
      .item {
        padding: 2px 10px;
        font-size: 0.8;
      }
    }

    .item {
      cursor: pointer;
      display: flex;
    }

    .item:hover {
      background-color: #e7e7e7;
    }

    .item img,
    .item .label {
      flex: 0 0 auto;
      vertical-align: middle;
      object-fit: none;
    }

    .item .label {
      padding-left: 10px;
      white-space: nowrap;
    }

    .item.disabled {
      color: lightgray;
    }

    .item.disabled img {
      opacity: 0.5;
    }

    .description {
      font-size: 0.8em;
      padding: 0 0 5px 20px;
    }

    .link {
      cursor: pointer;
    }

    .tag-group + .tag-group {
      border-top: 1px solid lightgray;
    }

    .hide {
      display: none !important;
    }

    .show-btn {
      fill: white;
      background: var(--theme-color-primary, #1d1d1b);
    }

    .show-btn:hover {
      fill: var(--theme-color-primary, white);
      background: var(--theme-color-secondary, #1d1d1b);
    }

    .close-btn {
      padding: 5px 10px;
      float: right;
      cursor: pointer;
      fill: lightgray;
    }
    .close-btn:hover {
      fill: dimgray;
    }
  `;

  constructor() {
    super();
    this.value = [];
    this.options = [];
    this.addstyle = '';
    this.disabled = {};
    this.forceFullDisplay = false;
  }

  static get properties() {
    return {
      label: { type: String },
      closeable: { type: Boolean },
      forceFullDisplay: { type: Boolean },
      collapsed: { type: Boolean },
      addstyle: { type: String },
      options: { type: Array },
    };
  }

  /* eslint-disable-next-line class-methods-use-this */
  getAbsoluteCount(opt) {
    const absoluteCount = {};
    for (const [key, value] of Object.entries(opt.tagCount)) {
      key.split('|').forEach(item => {
        if (absoluteCount[item]) {
          absoluteCount[item] += value;
        } else {
          absoluteCount[item] = value;
        }
      });
    }
    return absoluteCount;
  }

  firstUpdated() {
    this.options.forEach(opts => {
      opts.items.forEach(opt => {
        opt.forEach(item => {
          if (item.deactivate) {
            this.toggle(item, opts);
          }
        });
      });
    });
  }

  _getCount(name, opt) {
    if (opt.tagCount) {
      const abscnt = this.getAbsoluteCount(opt)[name];
      return abscnt ? `(${abscnt})` : '';
    }
    return '';
  }

  _getEntry(option, opt) {
    const tagCount = this._getCount(option.name, opt);
    return opt.hideEmptyClasses && !tagCount
      ? html``
      : html` <div
          tabindex="0"
          class="item ${this.disabled[option.name]
            ? 'disabled'
            : ''} ${option.hidden ? 'hide' : ''}"
          @click="${() => {
            opt.notInteractive ? null : this.toggle(option, opt);
          }}"
        >
          <img .src="${option.icon}" alt="" />
          <div class="label">${option.label} ${tagCount}</div>
        </div>`;
  }

  updateTagCount(tagCount: any, index: number) {
    if (index < this.options.length) {
      this.options[index].tagCount = tagCount;
      this.requestUpdate();
    } else {
      console.warn('tried to update tag count for non existing option');
    }
  }

  _renderOptions(opt) {
    // TODO
    return html`
      ${opt.items &&
      opt.items.map(
        options =>
          html`<div class="tag-group">
            ${options.map(option => this._getEntry(option, opt))}
          </div>`,
      )}
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderBtn() {
    return html`
      <ki-icon-btn
        icon="ki ki-legend"
        @click="${() => {
          this.renderRoot.querySelector('#legends').show();
        }}"
      >
      </ki-icon-btn>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderShowBtn() {
    return html`
      <ki-icon-btn
        class="show-btn"
        icon="ki ki-legend"
        @click="${() => {
          this.collapsed = false;
        }}"
      >
      </ki-icon-btn>
    `;
  }

  _renderCloseBtn() {
    return html`
      <ki-icon
        class="close-btn"
        icon="ki ki-chevron-down"
        @click="${() => {
          this.collapsed = true;
        }}"
      >
      </ki-icon>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderDescr(opt, mobile) {
    let ret = html``;
    const desc = opt.description;
    if (desc && desc.text && desc.link) {
      ret = html` <div
        class="description link"
        onclick="window.open('${desc.link}','_self')"
      >
        ${desc.text}
      </div>`;
    } else if (desc && desc.text) {
      ret = html`
        <div
          class="description"
          style="text-align:${mobile ? 'center;' : 'inital;'}"
        >
          ${unsafeHTML(mobile ? desc.text.replaceAll('<br>', '') : desc.text)}
        </div>
      `;
    }
    return ret;
  }

  toggleAll(opt) {
    opt.items.forEach(grp => {
      grp.forEach(_opt => {
        this.disabled[_opt.name] = !this.disabled[_opt.name];
      });
    });
    this.toggle(null, opt);
  }

  render() {
    // language=html
    return this.options.map(opt => {
      const descr = opt.description;
      if (ViewPort.size === SM && !this.forceFullDisplay) {
        return html`
          ${this._renderBtn()}
          <ki-modal id="legends" closeable .label="${opt.label}">
            <div class="legends-list">
              ${this._renderOptions(opt)}
              <div class="tag-group">
                ${descr ? this._renderDescr(opt, true) : ''}
              </div>
            </div>
          </ki-modal>
        `;
      }
      this.style.cssText += this.addstyle;
      return html`
        ${(this.closeable && this.collapsed && this._renderShowBtn()) || ''}
        <div
          class="legends-list ${this.closeable && this.collapsed ? 'hide' : ''}"
        >
          ${(this.closeable && !this.collapsed && this._renderCloseBtn()) || ''}
          <div
            class="name"
            @click="${() => {
              opt.notInteractive ? null : this.toggleAll(opt);
            }}"
          >
            ${opt.label}
          </div>
          ${descr && descr.position === 'top' ? this._renderDescr(opt) : ''}
          ${this._renderOptions(opt)}
          ${descr && descr.position !== 'top' ? this._renderDescr(opt) : ''}
        </div>
      `;
    });
  }

  toggle(option, opt) {
    if (option) {
      this.disabled[option.name] = !this.disabled[option.name];
    }
    const value = flatten(opt.items)
      .filter(op => !this.disabled[op.name])
      .map(op => op.name);
    if (!value.length) {
      this.toggleAll(opt);
    } else {
      this.dispatchEvent(new CustomEvent('changed', { detail: { value } }));
      this.requestUpdate();
    }
  }
}
