// const station = {
//      "station_no": ["nlsLabel", "1430"],
//     ...
//      "data": [["2021-03-16T00:00:00.000+01:00", 185], ["2021-03-16T00:15:00.000+01:00", 184]]
//  };

/**
 * Download station data as excel
 *
 * @param {object} station - The station data to be download
 * @param {object} [options] - Options to customize the excel content
 *          {
 *              name: "excelfilename",
 *              sheetName: "sheetname",
 *              meta: ['station_name', 'station_no', 'parametertype_name', 'ts_unitsymbol',...]
 *          }
 */
export async function downloadExcel(station = {}, options = {}) {
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  if (station) {
    const ws = XLSX.utils.aoa_to_sheet(station.metadata);
    // Add ts data
    XLSX.utils.sheet_add_aoa(ws, station.data, { origin: -1 });
    ws['!cols'] = [{ wch: 30 }];
    XLSX.utils.book_append_sheet(wb, ws, options.sheetName || 'Sheet1');
  }
  XLSX.writeFile(wb, `${options.name || 'download'}.xlsx`);
}

/**
 * Download station data as csv
 * @param {object} station - The station data to be download
 * @param {object} [options] - Options to customize the excel content
 *          {
 *              name: "excelfilename",
 *              sheetName: "sheetname",
 *              meta: ['station_name', 'station_no', 'parametertype_name', 'ts_unitsymbol',...]
 *          }
 */
export async function downloadCSV(station = {}, options = {}) {
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  if (station) {
    const ws = XLSX.utils.aoa_to_sheet(
      station.metadata.map(item => ['#', ...item]),
    );
    // Add ts data
    XLSX.utils.sheet_add_aoa(ws, station.data, { origin: -1 });
    XLSX.utils.book_append_sheet(wb, ws, options.sheetName || 'Sheet1');
  }
  XLSX.writeFile(wb, `${options.name || 'download'}.csv`, {
    bookType: 'csv',
    FS: '\t',
  });
}
