import { isEqual } from 'lodash-es/lang';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import LegendSwitcher from './LegendSwitcher';
import nls from '../../locales/index';

@customElement('ki-legend-control')
export default class KiLegendControl extends Mix(_AddOn, [i18nMixin, { nls }]) {
  static get properties() {
    return {
      options: {
        type: Object,
        hasChanged(newVal, oldVal) {
          return !isEqual(newVal, oldVal);
        },
      },
      addstyle: { type: String },
      // for all addOn controller ?
    };
  }

  _create() {
    this.legendSwitcher = new LegendSwitcher({
      name: this.i18n.t('Legends'),
      displayInLayerControl: true,
      group: 'Information',
      options: this.options,
      addstyle: this.addstyle,
    });
    this.map.addControl(this.legendSwitcher);
  }

  _tear() {
    this.map.removeControl(this.legendSwitcher);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    if (_changedProperties.has('options') && this.legendSwitcher) {
      this.legendSwitcher.options = this.options;
    }
    if (_changedProperties.has('addstyle') && this.legendSwitcher) {
      this.legendSwitcher.addstyle = this.addstyle;
    }
    if (_changedProperties.has('disabled') && this.legendSwitcher) {
      this.legendSwitcher.disabled = this.disabled;
    }
  }
}
