import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { containsCoordinate } from 'ol/extent';
import { get as getProjection } from 'ol/proj';

let registeredProjections = false;

export function registerProjections(mapCfg) {
  if (mapCfg.projections && !registeredProjections) {
    mapCfg.projections.forEach(proj => {
      const extent = proj.length === 3 ? proj.pop() : null;
      proj4.defs(proj[0], proj[1]);
      const olProj = getProjection(proj[0]);
      if (olProj && extent) {
        olProj.setExtent(extent);
      }
    });
    registeredProjections = true;
    register(proj4);
  }
}

export function reproject(data, mapCfg) {
  try {
    registerProjections(mapCfg);
  } catch (e) {
    console.error('error registering projections', mapCfg);
  }

  const viewProjection = mapCfg?.view?.projection || 'EPSG:3857';
  const dataProjection = mapCfg?.dataProjection || 'EPSG:4326';
  let x = 'station_longitude';
  let y = 'station_latitude';
  if (mapCfg.crs === 'regional') {
    x = 'station_carteasting';
    y = 'station_cartnorthing';
  } else if (mapCfg.crs === 'local') {
    x = 'station_local_x';
    y = 'station_local_y';
  }

  const stations = [];

  data.forEach(station => {
    if (station[x] && station[y]) {
      const point =
        dataProjection !== viewProjection
          ? proj4(dataProjection, viewProjection, [
              parseFloat(station[x]),
              parseFloat(station[y]),
            ])
          : [parseFloat(station[x]), parseFloat(station[y])];
      if (station.Web_Offset) {
        try {
          const offset = JSON.parse(station.Web_Offset);
          point[0] += offset[0];
          point[1] += offset[1];
        } catch (e) {
          console.error('Could no parse station offset');
        }
      }
      if (mapCfg.view.extent) {
        if (containsCoordinate(mapCfg.view.extent, point)) {
          station.point = point;
          stations.push(station);
        } else {
          console.warn('station outside exten', station);
        }
      } else {
        station.point = point;
        stations.push(station);
      }
    }
  });
  // console.log("reproject: ", new Date().getTime()-start)
  return stations;
}
