import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import StationMapOverviewLayer from './StationMapOverviewLayer';

@customElement('ki-station-map-overview-control')
export default class KiStationMapOverviewControl extends _AddOn {
  constructor() {
    super();
    this.displayInLayerControl = false;
  }

  static get properties() {
    return {
      label: { type: String },
      layerOptions: { type: Object },
      displayInLayerControl: { type: Boolean },
    };
  }

  _create() {
    if (this.layerOptions && this.layerOptions.layer.length) {
      this.stationMapOverviewLayer = new StationMapOverviewLayer({
        layerOptions: this.layerOptions,
        name: 'Map overview',
        displayInLayerControl: this.displayInLayerControl,
        group: 'Information',
        label: this.label,
        selectedStations: this.selectedStations,
        map: this.map,
      });
      this.map.addControl(this.stationMapOverviewLayer);
    }
  }

  _tear() {
    this.map.removeControl(this.stationMapOverviewLayer);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
