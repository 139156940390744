/* eslint-disable max-classes-per-file */
import { LoaderMixin, Mix, template } from '@kisters/wcp-base/common';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { css, html, LitElement } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import { i18nMixin, responsiveMixin } from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import nls from '@kisters/wcp-water/locales';
// eslint-disable-next-line import/extensions
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { last, first } from 'lodash-es';
import { getCurrentApi } from '../../api/API';
import '@ui5/webcomponents/dist/Carousel.js';

@customElement('wwp-station-info')
export default class WwpStationInfo extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    }

    .content {
      margin: 15pt;
      height: auto;
      flex: 1;
    }

    :host(.sm-screen) .content,
    :host(.md-screen) .content {
      width: 100%;
      margin: 0px;
      margin-right: 20px;
      display: table;
    }

    :host(.sm-screen) .content:first-of-type {
      margin-top: 50px;
    }

    :host(.md-screen) .content:first-of-type {
      margin-top: 25px;
    }

    ui5-carousel {
    }

    .images {
      margin: 15pt;
      height: 100%;
      max-height: 95%;
      flex: 1;
      min-width: 200px;
    }

    .images img {
      display: block;
      margin: auto;
      max-width: 90%;
      max-height: 90%;
    }

    .row {
      display: table-row;
      line-height: 1.5em;
    }

    .footer {
      display: table-row;
      line-height: 2em;
    }

    .row {
      width: 100%;
      height: 15px;
    }

    .cell {
      display: table-cell;
      padding: 5px 10px;
    }

    :host(.sm-screen) .cell {
      height: 34px;
    }

    .label {
      color: gray;
      padding-left: 30px;
    }

    .hidden {
      display: none;
    }

    .value {
      overflow-wrap: break-word;
    }

    .url {
      text-decoration: none;
    }

    :host(.sm-screen) .row {
      height: unset;
    }
  `;

  @query('#gallery')
  gallery;

  // eslint-disable-next-line class-methods-use-this
  get api() {
    return getCurrentApi();
  }

  static get properties() {
    return {
      station: {
        type: Object,
        initial: () => {},
      },
      fields: {
        type: Array,
        initial: () => ['station_no', 'station_name'],
      },
    };
  }

  disconnectedCallback() {
    super.connectedCallback();
    clearInterval(this.interval);
  }

  getLabel(field) {
    if (field.label) {
      return field.label;
    }
    return this.i18n.exists(`kiwis:${field.field}`)
      ? this.i18n.t(`kiwis:${field.field}`)
      : this.i18n.t(`customer:${field.field}`);
  }

  getValue(field) {
    let ret;
    let _skipSubst = false; // Skip string modification after parsing
    const val = this.station[field.field];
    if (!val) {
      ret = '-';
    } else if (field.type === 'number') {
      ret = new Intl.NumberFormat(
        this.i18n.language,
        field.numberFormat || this.numberFormat || {},
      ).format(parseFloat(val));
    } else if (field.type === 'textnumber') {
      ret = new Intl.NumberFormat(
        this.i18n.language,
        field.numberFormat || this.numberFormat || {},
      ).format(parseFloat(val.replace(',', '.')));
    } else if (field.type === 'datetime') {
      if (field.subst) {
        ret = val;
        field.subst.forEach(s => {
          ret = ret.replace(s.token, s.with);
        });
        _skipSubst = false;
      }
      const _dt = dayjs(ret, field.dateFormat?.inputPattern);
      ret = _dt.format(field.dateFormat?.outputFormat);
    } else if (field.type === 'html') {
      ret = unsafeHTML(this.station[field.field].replaceAll('\r\n', '<br>'));
    } else if (field.type === 'url') {
      // Check if URL is provided (either hardcoded or dynamic as station parameter)
      if (
        (field.urlIsStationParameter && this.station[field.url]) ||
        (!field.urlIsStationParameter && field.url)
      ) {
        let _url = '<a class="url" href="';
        if (field.urlIsStationParameter) _url += this.station[field.url];
        else _url += field.url;
        _url += '"/>';
        _url += val;
        _url += '</a>';
        ret = unsafeHTML(_url);
      } else ret = unsafeHTML(val);
    } else {
      ret = this.station[field.field];
    }

    if (ret !== '-' && field.translate) {
      ret = this.i18n.t(ret);
    }

    if (ret !== '-' && field.subst && _skipSubst) {
      if (Array.isArray(field.subst)) {
        let tmpret = val;
        field.subst.forEach(item => {
          tmpret = tmpret.replace(item.token, item.with);
        });
        ret = tmpret;
      } else {
        ret = val.replace(field.subst.token, field.subst.with);
      }
    }
    if (ret !== '-' && field.suffix) {
      ret = template(`${ret}${field.suffix}`, this.station);
    }
    return ret;
  }

  getSlider() {
    // eslint-disable-next-line lit-a11y/alt-text
    return html`<ui5-carousel id="gallery" arrows-placement="Navigation" cyclic>
      ${this.images?.map(i => html`<img alt="station" src="${i}" />`)}
    </ui5-carousel>`;
  }

  getTable(fields) {
    return fields.map(field => {
      const val = this.getValue(field);
      return val !== '-'
        ? html`<div class="row">
            <div class="cell label">${this.getLabel(field)}</div>
            <div
              style="${field.style || ''}"
              class="cell value ${field.type || ''}"
            >
              ${val}
            </div>
          </div>`
        : html``;
    });
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.stationId = params.stationId;
    this.station = await this.api.getStation(this.stationId);
    Object.assign(this, params.options);
    if (this.tsData) {
      this.fetchTsData();
    }
    if (this.showImages) {
      this.fetchImages();
      let count = 0;
      this.interval = setInterval(() => {
        count += 1;
        this.gallery.navigateTo(count % this.images.length);
      }, 10000000);
    }
  }

  render() {
    return html` ${this.tables?.map(
        table => html`<div class="content">
          ${this.getTable(table.items)}
          ${table.footer
            ? html`<div class="footer">
                <div class="label">
                  ${table.footer &&
                  this.tableHasValues(this.getTable(table.items))
                    ? unsafeHTML(table.footer)
                    : ''}
                </div>
              </div>`
            : ``}
        </div>`,
      )}

      <div class="images ${this.images && this.images.length ? '' : 'hidden'}">
        ${this.images && this.getSlider()}
      </div>`;
  }

  // TODO: Dirty, LANUV-167
  // eslint-disable-next-line class-methods-use-this
  tableHasValues(templateResultList) {
    let anyValueFound = false;
    templateResultList.forEach(tr => {
      if (tr.values?.length) anyValueFound = true;
    });
    return anyValueFound;
  }

  async fetchImages() {
    this.images = await this.api.getImage(this.station.station_no);
    this.requestUpdate();
    return this.images;
  }

  async fetchTsData() {
    await Promise.all(
      this.station.timeseries
        .filter(ts => this.tsData.includes(last(ts.href.split('/'))))
        .map(async ts => {
          const tsdata = await this.api.getTsData(ts.href);
          tsdata.forEach(item => {
            this.station[item.ts_shortname] = last(last(item.data));
            const from = first(first(item.data));
            const to = first(last(item.data));
            if (from && to) {
              this.station[`${item.ts_shortname}_from`] = from;
              this.station[`${item.ts_shortname}_to`] = to;
              this.station[`${item.ts_shortname}_coverage`] = `${dayjs(
                from,
              ).format('L LT')} - ${dayjs(to).format('L LT')}`;
            }
          });
        }),
    );
  }
}
