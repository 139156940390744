import { css } from 'lit';

// language=CSS
export default css`
  :host {
    display: block;
    overflow: hidden;
  }

  .hide {
    display: none !important;
  }

  ki-layers-control-element {
    position: absolute;
    top: 200px;
    right: 20px;
    z-index: 222;
    bottom: unset;
  }

  #map {
    height: 100%;
    width: 100%;
    transform-origin: left;
  }

  ki-icon-btn[active]{
    background-color:var(--theme-color-primary, #0056a0);
    fill: white;
  }

  ki-icon-btn,
  .icon-btn {
    fill: gray;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 40px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
  }

  ki-icon-btn ki-icon,
  .icon-btn ki-icon {
    display: block;
  }

  ki-icon-btn:hover,
  .icon-btn:hover {
    fill: #4a4a49;
  }

  .download-btn {
    top: 15px;
    right: 20px;
  }

  ki-icon-switcher {
    position: absolute;
    display: flex;
    top: 250px;
    flex-direction: column;
    right: 20px;
  }

  ki-legend {
    bottom: 20px;
    left: 20px;
    transition: 0.6s all;
  }

  .legends-btn {
    top: 15px;
    right: 15px;
    display: none;
  }

  .dragbox {
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px solid #1093cd;
    background: rgba(16, 147, 205, 0.4);
  }

  :host(.sm-screen) ki-layers-control-element {
    height: auto;
    top: unset;
    right: unset;
    left: 5px;
    bottom: 40px;
    z-index: unset;
  }

  :host(.sm-screen) ki-legend {
    display: block;
    top: unset;
    bottom: 90px;
    right: 15px;
    left: unset;
  }

  :host(.sm-screen) ki-station-map-zoom-button {
    bottom: 40px;
    top: unset;
  }

  :host(.sm-screen) .location-btn {
    top: unset;
    right: 15px;
    bottom: 140px;
    padding: 0;
  }

  :host(.sm-screen) #scale-info,
  :host(.sm-screen) .download-btn,
  :host(.sm-screen) ki-icon-switcher,
  :host(.sm-screen) .ol-scale-line,
  :host(.sm-screen) #overview-map {
    display: none;
  }

  table.datatable{
    margin: 5px 10px;
    border-collapse: collapse;
  }

  .datatable td, .datatable th{
    padding: 5px 10px;
  }

  select.control{
    border-width:0;
    background: white;
    border-radius:5px;
  }
  ki-player {
    position: absolute;
    bottom: 0px;

    left: 20%;
    width:70%;
    pointer-events: all;
  }

  .ol-scale-line {
    bottom: 0;
    left: 0;
    position: relative;
  }

  div.ol-scale-line,
  .custom-mouse-position {
    background: transparent;
    display: flex;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    font-size: 10px;
  }
  .custom-mouse-position .info-box {
    align-self: center;
    font-size: 1.3em;
    padding: 0 10px;
  }
  .custom-mouse-position select {
    border-width: 0;
    color: rgb(74, 74, 73);
  }
  div.ol-scale-line-inner {
    color: #4a4a49;
    border: 1px solid #4a4a49;
    border-top: none;
  }
  #scale-info {
    display: flex;
    position: absolute;
    left: unset;
    right: 10px;
    bottom: 5px;
    background: white;
    color: #4a4a49;
    opacity: 0.8;
    padding: 2px;
  }
  div.ol-attribution.ol-uncollapsible {
    bottom: 5px;
    right: 50%;
    transform: translateX(50%);
    color: rgb(74, 74, 73);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ol-attribution ul li {
    padding: 0 5px;
    color: rgb(74, 74, 73);
  }
  #overview-map {
    position: absolute;
    right: 15px;
    bottom: 40px;
    width: 180px;
    height: 150px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .ol-popup.default {
    position: fixed !important;
    z-index: 111;
  }

  .ol-popup.default div {
    border: none;
    max-height: 90%;
    overflow: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 0;
  }
  .ol-popup.default .anchor {
    color: rgba(0, 0, 0, 0.14);
  }

  .tooltip-content {
    white-space: nowrap;
  }

  .ol-overlaycontainer-stopevent {
    z-index: unset !important;
  }

  .time-control-btn{
    position: absolute;
    bottom: 65px;
    right: 20px;
    z-index: 222;
    top: unset;
    pointer-events: all;
  }
`;
