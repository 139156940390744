import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import mapKeys from 'lodash-es/mapKeys';
import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';

// TODO refactor ki-wcp-walhydro

@customElement('ol-vector-layer')
export default class OlVectorLayer extends _AddOn {
  static get properties() {
    return {
      features: { type: Array, initial: () => [] }, // Feature list
      zIndex: { type: String, attribute: 'z-index' },
      visible: { type: Boolean, default: true },
    };
  }

  _create() {
    // todo override lifecycle?

    this.layer = new VectorLayer({
      zIndex: this.zIndex,
      source: new VectorSource(this.source),
    });
    this.map.addLayer(this.layer);
    this._updateProperties();
  }

  _tear() {
    this.map.removeLayer(this.layer);
  }

  _updateProperties() {
    // TODO
    if (!this.layer) return;
    //  TODO move to ol-layer
    mapKeys(this.constructor.properties, (value, key) => {
      const updateFn = `_update${key.replace(/^(.)/, (s0, s1) =>
        s1.toUpperCase(),
      )}`;
      if (this[updateFn]) {
        this[updateFn](this[key]);
      } else {
        this.layer.set(key, this[key]);
      }
    });
  }

  _updateFeatures(features) {
    this.layer.getSource().clear();
    this.layer.getSource().addFeatures(features);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    this._updateProperties();
  }
}
