/* eslint-disable no-return-assign */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { getSearchParam, setSearchParam } from '@kisters/wcp-base/app';
import { getQueryObject } from '@kisters/wcp-base/decorators';
import '@kisters/wcp-base/components/ki-icon/ki-icon-btn';
import '../../components/ki-dynamic-layout/ki-dynamic-layout';
import {
  elementType,
  getExampleLayout,
  getLayout,
  rowType,
  saveLayout,
} from '../../components/ki-dynamic-layout/service/layout-service';

@customElement('ww-dashboard')
export default class WwDashboard extends LitElement {
  static styles = css`
    :host {
      display: block;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
    .dashboard-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 20px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .dashboard-input-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
      box-sizing: border-box;
    }
    .dashboard-text-input {
      height: 50px;
      padding: 13px 8px;
      border: 1px solid #4d446c;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 100px;
      width: 100%;
    }
    .input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      min-width: 100px;
      box-sizing: border-box;
    }
    .input-label {
      margin-bottom: 5px;
    }
    .grow-1 {
      flex-grow: 1;
    }
    .grow-2 {
      flex-grow: 2;
    }
  `;

  layout: {
    rowType: rowType;
    elementType?: elementType;
    configuration?: { text: string };
  }[][] = [];

  name: string = '';

  description: string = '';

  static get properties() {
    return {
      layout: { type: Array, attribute: false },
      name: { type: String, attribute: false },
      description: { type: String, attribute: false },
    };
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  async onAfterEnter() {
    const uuidToLoad = getSearchParam('uuid');
    if (uuidToLoad) {
      const loaded = await getLayout(uuidToLoad);
      this.layout = loaded.layout;
      this.name = loaded.name;
      this.description = loaded.description;
    } else {
      this.layout = getExampleLayout();
      this.name = 'Example Layout';
      this.description = 'Example Description';
    }
  }

  render() {
    return html`
      <div class="dashboard-container">
        <div class="dashboard-input-container">
          <div class="input-wrapper grow-1">
            <span class="input-label"> Name </span>
            <input
              type="text"
              id="input-name"
              name="input-name"
              class="dashboard-text-input"
              .value="${this.name}"
              @change=${e => (this.name = e.target.value)}
            />
          </div>
          <div class="input-wrapper grow-2">
            <span class="input-label"> Description </span>
            <input
              type="text"
              id="input-descr"
              name="input-descr"
              class="dashboard-text-input"
              .value="${this.description}"
              @change=${e => (this.description = e.target.value)}
            />
          </div>
          <div class="button-wrapper">
            <ki-icon-btn
              icon="ki ki-save"
              class="toggle-btn"
              @click="${this.saveLayout}"
            ></ki-icon-btn>
          </div>
        </div>
        <ki-dynamic-layout .layout="${this.layout}"></ki-dynamic-layout>
      </div>
    `;
  }

  async saveLayout() {
    const newLayout = getQueryObject(this, 'ki-dynamic-layout').layout;

    const newUuid = await saveLayout(this.name, this.description, newLayout);

    if (newUuid) {
      setSearchParam('uuid', newUuid);
    }
  }
}
