/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-nested-ternary */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { initConfig, getRoutes } from '@kisters/wcp-base/app';
import { LoaderMixin } from '@kisters/wcp-base/common';
import { init } from '@kisters/wiski-web/api';

import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-ext';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-precip-ext';
import '@kisters/wiski-web/views/ww-report/ww-report';

// Import common components here
import '@kisters/ww-hdoe-common/components/ww-sample-component';

import importActions from './import-actions/import-actions';
import './components/ki-station-info-hdoe';
import './components/wwp-station-download-hdoe';
import './components/wwp-hdoe-kennzahlen';
import './components/wwp-hdoe-querprofil';
import './components/wwp-hdoe-hochwasserbericht';
import './components/wwp-hdoe-bericht-wasserstand';
import './components/wwp-hdoe-start';
import './components/ww-hdoe-hydrochar';

// TODO use lib without compile might be better.
// can consider all ki-wcp project with build version instead import source and recompile it

@customElement('ww-app-index')
export default class wwAppIndex extends LoaderMixin(LitElement) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }
  `;

  constructor() {
    super();
    this.configLoaded = false;
    this.configLocation = './config/app.json';
  }

  render() {
    if (!this.configLoaded) {
      return this._renderLoader();
    }
    // language=html
    return html` <ki-app-index .route="${getRoutes()}"></ki-app-index> `;
  }

  firstUpdated() {
    this.promiseLoader(
      initConfig(this.configLocation, importActions).then(config => {
        init({ dataPath: config.apiPath });
        this._config = config;
        this.configLoaded = true;
      }),
    );
  }
}
