import { customElement } from 'lit/decorators.js';
import { getLayer } from '../../common/ki-ol-layer-generator';
import _AddOn from './_AddOn';

@customElement('ki-layer-factory')
export default class KiLayerFactory extends _AddOn {
  static get properties() {
    return {
      additionalLayers: { type: Array },
    };
  }

  constructor() {
    super();
    this.mapLayers = [];
  }

  _create() {
    this.mapLayers = this.additionalLayers.map(c => getLayer(c));
    this.map.getLayers().extend(this.mapLayers);
  }

  _tear() {
    this.mapLayers.forEach(l => this.map.removeLayer(l));
    this.mapLayers = [];
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
