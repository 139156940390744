import {
  i18nMixin,
  responsiveMixin,
  SM,
  ViewPort,
} from '@kisters/wcp-base/decorators';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import _AddOn from './_AddOn';
import PopupSwitcher from './PopupSwitcher';
import nls from '../../locales/index';

@customElement('ki-popup-control')
export default class KiPopupControl extends Mix(
  _AddOn,
  [i18nMixin, { nls }],
  responsiveMixin,
) {
  static get properties() {
    return {
      addstyle: { type: String },
      // for all addOn controller ?
    };
  }

  _create() {
    this.popupSwitcher = new PopupSwitcher({
      name: this.i18n.t('PopupSwitcher'),
      displayInLayerControl: ViewPort.size !== SM,
      group: 'Information',
      addstyle: this.addstyle,
    });
    this.map.addControl(this.popupSwitcher);
  }

  _tear() {
    this.map.removeControl(this.popupSwitcher);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    if (this.popupSwitcher) {
      this.popupSwitcher.displayInLayerControl = ViewPort.size !== SM;
    }
    if (_changedProperties.has('addstyle') && this.popupSwitcher) {
      this.popupSwitcher.addstyle = this.addstyle;
    }
    if (_changedProperties.has('disabled') && this.popupSwitcher) {
      this.popupSwitcher.disabled = this.disabled;
    }
  }
}
