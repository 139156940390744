import { html } from 'lit';

export function StatusIcon(image, label = '', col) {
  return html` <div
    class="cell col-__tag"
    style="${col.css || ''}text-align: center"
  >
    <img src="${image}" alt="${label}" title="${label}" />
  </div>`;
}
