import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';

import { i18nMixin } from '@kisters/wcp-base/decorators';
import nls from '../../locales/index';

@customElement('ki-station-map-zoom-button')
export default class KiStationMapZoomButton extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = [
    css`
      :host {
        position: absolute;
        top: 70px;
        right: 20px;
        pointer-events: all;
      }

      .zoom-btns {
        fill: var(--theme-text-color-tertiary, gray);
        background: var(--theme-color-tertiary, white);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        width: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        font-size: 20px;
        cursor: pointer;
        overflow: hidden;
      }

      .zoom-btns .btn:hover ki-icon::part(icon) {
        fill: #4a4a49;
      }
    `,
  ];

  static get properties() {
    return {
      map: { Object },
    };
  }

  render() {
    // language=html
    return html`
      <div class="zoom-btns" id="zoom-btns">
        <div
          tabindex="0"
          title="${this.i18n.t('Zoom_in_map')}"
          class="btn"
          id="zoom-in-btn"
          @click="${this.zoomIn}"
          alt="Zoom in"
        >
          <ki-icon icon="ki ki-plus"></ki-icon>
        </div>
        <div
          tabindex="0"
          title="${this.i18n.t('Zoom_out_map')}"
          class="btn"
          id="zoom-out-btn"
          @click="${this.zoomOut}"
          alt="Zoom out"
        >
          <ki-icon icon="ki ki-minus"></ki-icon>
        </div>
        <div
          tabindex="0"
          title="${this.i18n.t('Zoom_to_home')}"
          class="btn"
          id="home-btn"
          @click="${this.zoomToHome}"
          style="display:${this.homeExtent ? 'inital' : 'none'}"
          alt="Hone"
        >
          <ki-icon icon="ki ki-home"></ki-icon>
        </div>
      </div>
    `;
  }

  zoomIn() {
    this.map.getView().animate({
      zoom: this.map.getView().getZoom() + 1,
      duration: 500,
    });
  }

  zoomToHome() {
    this.map.getView().fit(this.homeExtent, { duration: 500 });
  }

  zoomOut() {
    this.map.getView().animate({
      zoom: this.map.getView().getZoom() - 1,
      duration: 500,
    });
  }
}
