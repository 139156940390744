import dayjs from '@kisters/wcp-base/common/dayjsext';
import { orderBy, get } from 'lodash-es';
import { template } from '@kisters/wcp-base/common';

function creatRule(key, rule) {
  if (rule && typeof rule === 'object') {
    const _getValue = item => get(item, key);
    let getValue = item => _getValue(item);
    if (rule.last) {
      getValue = item =>
        (_getValue(item)?.length ?? 0) > 0
          ? _getValue(item)[_getValue(item).length - 1]
          : undefined;
    } else if (rule.first) {
      getValue = item =>
        (_getValue(item)?.length ?? 0) > 0 ? _getValue(item)[0] : undefined;
    }
    if (rule.resultIndex) {
      const oldGetValue = getValue;
      getValue = item =>
        (oldGetValue(item)?.length ?? 0) >= rule.resultIndex
          ? oldGetValue(item)[rule.resultIndex]
          : undefined;
    }
    if (rule.in) {
      return item => rule.in.indexOf(item[key]) >= 0;
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'includes')) {
      return item => item[key].includes(rule.includes);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'exact')) {
      return item =>
        item[key].every(r => rule.exact.includes(r)) &&
        rule.exact.every(r => item[key].includes(r));
    }
    if (
      Object.prototype.hasOwnProperty.call(rule, 'max') &&
      Object.prototype.hasOwnProperty.call(rule, 'min')
    ) {
      return item => {
        const multiplier = rule.multiplier || 1;
        const max =
          typeof rule.max === 'string'
            ? template(rule.max, item)
                ?.replace(',', '.')
                .replace(/[^\d.-]/g, '')
            : rule.max;
        const min =
          typeof rule.min === 'string'
            ? template(rule.min, item)
                ?.replace(',', '.')
                .replace(/[^\d.-]/g, '')
            : rule.min;

        if (max === '' || min === '') {
          return false;
        }
        const _val = getValue(item);
        const value = rule.absolute ? Math.abs(_val) : _val;
        return (
          _val !== undefined &&
          value <= max * multiplier &&
          value >= min * multiplier
        );
      };
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'max')) {
      return item => {
        const multiplier = rule.multiplier || 1;
        const max =
          typeof rule.max === 'string'
            ? template(rule.max, item)
                ?.replace(',', '.')
                .replace(/[^\d.-]/g, '')
            : rule.max;

        const _val = getValue(item);
        const value = rule.absolute ? Math.abs(_val) : _val;
        return max !== '' && _val !== undefined && value <= max * multiplier;
      };
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'min')) {
      return item => {
        const multiplier = rule.multiplier || 1;

        const min =
          typeof rule.min === 'string'
            ? template(rule.min, item)
                ?.replace(',', '.')
                .replace(/[^\d.-]/g, '')
            : rule.min;

        const _val = getValue(item);
        const value = rule.absolute ? Math.abs(_val) : _val;
        return min !== '' && _val !== undefined && value >= min * multiplier;
      };
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'not')) {
      const opsRule = creatRule(key, rule.not);
      return item => !opsRule(item);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'duration')) {
      const last = dayjs().tz().subtract(dayjs.duration(rule.duration));
      return item => dayjs(getValue(item)).tz() >= last;
    }
    // TODO no rule
    return () => true;
  }
  if (key === 'exists') {
    return item => item[rule] !== undefined && item[rule] !== null;
  }
  return item => get(item, key) === rule;
}

// TODO move out.
// TODO use symbol for _default
export default class Classifier {
  constructor({ label, tags = [], tagProperty = '__tag' } = {}) {
    this.label = label;
    this.valueProperty = 'ts_value';
    this.tagProperty = tagProperty;

    if (!Array.isArray(tags[0])) {
      tags = [tags];
    }

    this.tagGroups = tags.map(tgs =>
      orderBy(tgs, 'priority').map(tag => ({
        ...tag,
        filter: this._createFilterFn(tag.filter),
      })),
    );
  }

  _classify(dataItem) {
    dataItem = { ...dataItem, [this.tagProperty]: '_default' };
    this.tagGroups.forEach(tgs => {
      tgs.some((item, i) => {
        if (item.filter(dataItem)) {
          if (
            !dataItem[this.tagProperty] ||
            dataItem[this.tagProperty] === '_default'
          ) {
            dataItem[this.tagProperty] = item.name;
            if (item.invalidateValue) {
              dataItem.invalidatedValue = dataItem[this.valueProperty];
              dataItem[this.valueProperty] = null;
            }
          } else {
            dataItem[this.tagProperty] += `|${item.name}`;
          }
          dataItem.__tagpriority = item.sortPriority || i;
          dataItem.__tag_label = item.label || '';
          return true;
        }
        return false;
      });
    });

    return dataItem;
  }

  classify(data) {
    return data.map(item => this._classify(item));
  }

  // able to override
  // eslint-disable-next-line class-methods-use-this
  _createFilterFn(filter) {
    if (typeof filter === 'function') {
      return filter;
    }

    // TODO use 3rd party standard filter syntax
    const rules = Object.keys(filter).map(key => creatRule(key, filter[key]));
    return function filterFn(item) {
      return rules.reduce((prev, rule) => prev && rule(item), true);
    };
  }

  getTag(station) {
    return station[this.tagProperty];
  }
}
