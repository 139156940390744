/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { merge, last } from 'lodash-es';
import { getTimestampFromDuration } from '../../common/ts-utils';

export default function TsData(clz) {
  return class extends clz {
    static get properties() {
      return {
        options: { type: Object, initial: () => ({}) },
        getTsData: {
          type: Function,
          default: () => {
            throw new Error('getTsData is required: getTsData(tsName)');
          },
        },
      };
    }

    async _addTsDataToOptions(options) {
      // default
      options = merge({ xAxis: { type: 'datetime' } }, options);
      return {
        ...options,
        yAxis: {
          ...options.yAxis,
          plotLines: await Promise.all(
            options.xAxis?.plotLines
              ?.filter(p => !!p)
              ?.map(p => this._addTsDataToPlotLine(p, d => d?.[0]?.[1])) || [],
          ),
        },
        xAxis: {
          ...options.xAxis,
          ...this._addMinMaxFromPeriod(options.xAxis.period),
          plotLines: await Promise.all(
            options.xAxis?.plotLines
              ?.filter(p => !!p)
              ?.map(p => this._addTsDataToPlotLine(p, d => d?.[0]?.[0])) || [],
          ),
        },
        series: await Promise.all(
          options.series
            ?.filter(p => !!p)
            ?.map(this._addTsDataToSeries.bind(this)) || [],
        ),
      };
    }

    async _addTsDataToPlotLine(plotline, transform) {
      if (typeof plotline === 'string') {
        plotline = { ts: plotline };
      }
      let { value } = plotline;
      if (plotline.ts) {
        const data = await this.getTsData(plotline.ts);
        value = (plotline.transform || transform)(data);
      } else if (/^[+-]P/.test(plotline.value)) {
        // value is peroid
        value = getTimestampFromDuration(plotline.value.substr(1), {
          past: plotline.value.charAt(0) === '-',
        });
      }

      return {
        dashStyle: 'dash',
        width: 2,
        label: { text: plotline.ts, align: 'left' },
        ...plotline,
        value,
      };
    }

    // eslint-disable-next-line class-methods-use-this
    _addMinMaxFromPeriod(period) {
      if (period) {
        return {
          min: getTimestampFromDuration(period),
          max: getTimestampFromDuration(),
        };
      }
      return {};
    }

    async _addTsDataToSeries(series) {
      if (typeof series === 'string') {
        series = { ts: series };
      }
      if (series.ts) {
        let data = await this.getTsData(series.ts);
        data = data.map(d => [new Date(d[0]).getTime(), ...d.slice(1)]);
        series.transform?.(data);
        series = merge(
          { name: await this._resolveTsName(series.ts), data },
          series,
        );
      }
      return series;
    }

    async _resolveTsName(ts_path) {
      const ts_name = last(ts_path.split('/'));
      if (ts_name && ts_name[0] && ts_name[0].ts_name)
        return ts_name[0].ts_name;
      return ts_path;
    }
  };
}
