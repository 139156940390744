import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getLayer } from '../../common/ki-ol-layer-generator';

@customElement('ki-ol-layer')
export default class KiOlLayer extends LitElement {
  @property()
  type?: string;

  @property()
  url?: string;

  @property()
  label?: string;

  @property({ type: Boolean })
  visible = false;

  render() {
    return html``;
  }

  getOlLayer() {
    return getLayer({
      type: this.type,
      label: this.label,
      visible: this.visible,
    });
  }
}
