export const getTrendArrow = (dir, rotation) => {
  let deg;
  if (dir > 15) {
    deg = rotation ? 4.71238898038469 : '-90';
  } else if (dir > 5) {
    deg = rotation ? 5.497787143782138 : '-45';
  } else if (dir > -5) {
    deg = rotation ? 0 : '0';
  } else if (dir > -15) {
    deg = rotation ? 0.7853981633974483 : '45';
  } else if (dir > -Infinity) {
    deg = rotation ? 1.5707963267948966 : '90';
  }
  return deg;
};
