import { i18nMixin } from '@kisters/wcp-base/decorators';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import _AddOn from './_AddOn';
import StationMapControl from './StationMapControl';
import nls from '../../locales/index';
import './ki-station-map-zoom-button';

@customElement('ki-station-map-zoom-control')
export default class KiStationMapZoomControl extends Mix(_AddOn, [
  i18nMixin,
  { nls },
]) {
  static get properties() {
    return {
      label: { type: String },
      style: { type: String },
      homeExtent: { type: Array },
    };
  }

  _create() {
    const element = document.createElement('ki-station-map-zoom-button');
    element.homeExtent = this.homeExtent;
    this.stationMapZoomControl = new StationMapControl({
      element,
      homeExtent: this.homeExtent,
      name: 'Zoom',
      displayInLayerControl: false,
      group: 'Information',
      label: this.label,
      map: this.map,
      style: this.style,
    });
    this.map.addControl(this.stationMapZoomControl);
  }

  _tear() {
    this.map.removeControl(this.stationMapZoomControl);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
