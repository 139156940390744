import Control from 'ol/control/Control';
import StationsLayer from './StationsLayer';
import '../ki-legend/ki-legend';
/*
 * switch legend and set to all layers.
 * */
export default class LegendSwitcher extends Control {
  constructor(params = {}) {
    params = { visible: true, ...params };
    params.element = document.createElement('ki-legend');
    params.element.part = 'legend';
    super(params);
    this.options = Array.isArray(params.options)
      ? params.options
      : [params.options];
    this.setVisible(params.visible);
    this.addstyle = params.addstyle;
    this.setProperties({
      name: params.name,
      displayInLayerControl: params.displayInLayerControl,
      group: params.group,
    });
  }

  set description(description) {
    this.element.description = description;
  }

  set label(label) {
    this.element.label = label;
  }

  set options(options) {
    this.element.options = Array.isArray(options) ? options : [options];
  }

  set addstyle(style) {
    this.element.addstyle = style;
  }

  setMap(map) {
    super.setMap(map);
    // TODO  update flow
    this.element.addEventListener('changed', e => {
      this.getMap()
        .getLayers()
        .forEach(l => {
          if (l instanceof StationsLayer) {
            l.legends = e.detail.value;
          }
        });
    });
  }

  // move a parent class
  setVisible(flag) {
    this._visible = flag;
    this.element.classList.toggle('hide', !flag);
  }

  getVisible() {
    return this._visible;
  }
}
