function getAbsolutePath(path) {
  if (path.includes('//')) {
    return path;
  }
  return new URL(`${window.location.protocol}//${window.location.host}/${path}`)
    .href;
}

const callcache = {};
export default class KIWIS {
  constructor({
    basePath,
    service = 'kisters',
    type = 'queryServices',
    datasource = 0,
  }) {
    this.basePath = getAbsolutePath(basePath);
    this.service = service;
    this.type = type;
    this.datasource = datasource;
  }

  static _handleCSL(val) {
    if (Array.isArray(val)) {
      return val.join(',');
    }
    return val;
  }

  /**
   * Retrieves a list of stations with metadata.
   * @param params

 * @param {(String | String[] )} params.station_no ,
 * @param {(String | String[] )} params.station_id ,
 * @param {(String | String[] )} params.station_uuid ,
 * @param {(String | String[] )} params.station_name ,
 * @param {(String | String[] )} params.catchment_no ,
 * @param {(String | String[] )} params.catchment_id ,
 * @param {(String | String[] )} params.catchment_name ,
 * @param {(String | String[] )} params.site_no ,
 * @param {(String | String[] )} params.site_id ,
 * @param {(String | String[] )} params.site_uuid ,
 * @param {(String | String[] )} params.site_name ,
 * @param {String} params.stationgroup_id ,
 * @param {(String | String[] )} params.parametertype_id ,
 * @param {(String | String[] )} params.parametertype_name ,
 * @param {(String | String[] )} params.stationparameter_name ,
 * @param {(String | String[] )} params.object_type ,
 * @param {(String | String[] )} params.object_type_shortname ,
 * @param {String} params.bbox comma separated list with four values in order min_x, min_y, max_x, max_y; use 'crs' parameter to choose between local and global coordinates,
 * @param {String} params.fulltext fulltext filter for all station metadata fields,
 * @param {String} params.custattrfilter filter for one ore more custom station attributes in the form 'key1:value1;key2:value2;...'

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.crs] select which coordinates should be used for bbox filtering, possible values are 'localxy', 'regional' and 'global'; default is global; KML and LPK format will need global coordinates,
 * @param {String} [params.ca_site_returnfields] selects a set of custom defined site attributes (returnfield 'ca_site' must be active, allows comma separated list),
 * @param {String} [params.ca_sta_returnfields] selects a set of custom defined station attributes (returnfield 'ca_sta' must be active, allows comma separated list),
 * @param {String} [params.custattr_returnfields] DEPRECATED - use ca_sta_returnfields instead,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.flatten] if true, the list will be flattened to contain one station per station_id, all parameter and the object_type fields will be concatenated per entry; default is false,
 * @param {String} [params.addlinks] adds links for easier data discovery to the response
   * @param {('station_no'|'station_id'|'station_uuid'|'station_name'|'catchment_no'|'catchment_id'|'catchment_name'|'station_latitude'|'station_longitude'|'station_carteasting'|'station_cartnorthing'|'station_local_x'|'station_local_y'|'site_no'|'site_id'|'site_uuid'|'site_name'|'parametertype_id'|'parametertype_name'|'stationparameter_name'|'stationparameter_no'|'stationparameter_id'|'parametertype_longname'|'object_type'|'object_type_shortname'|'station_georefsystem'|'station_longname'|'station_area_wkt'|'station_area_wkt_org'|'river_id'|'river_name'|'area_id'|'area_name'|'ca_site'|'ca_sta'|'custom_attributes')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  station_no:
* 	  station_id:
* 	  station_uuid: Unique database independent ID
* 	  station_name:
* 	  catchment_no:
* 	  catchment_id:
* 	  catchment_name:
* 	  station_latitude:
* 	  station_longitude:
* 	  station_carteasting:
* 	  station_cartnorthing:
* 	  station_local_x:
* 	  station_local_y:
* 	  site_no:
* 	  site_id:
* 	  site_uuid: Unique database independent ID
* 	  site_name:
* 	  parametertype_id:
* 	  parametertype_name:
* 	  stationparameter_name:
* 	  stationparameter_no:
* 	  stationparameter_id:
* 	  parametertype_longname:
* 	  object_type: Station type, e.g. 'Surface Water'; Multiple values are concatenated with ; as separator
* 	  object_type_shortname: Station type shortname; Multiple values are concatenated with ; as separator
* 	  station_georefsystem:
* 	  station_longname:
* 	  station_area_wkt: the WKT string of the station extent if set
* 	  station_area_wkt_org: the original WKT string of the station extent if set
* 	  river_id:
* 	  river_name:
* 	  area_id:
* 	  area_name:
* 	  ca_site: returns custom site attributes, optionally use parameter ca_site_returnfields to select specific fields
* 	  ca_sta: returns custom station attributes, optionally use parameter ca_sta_returnfields to select specific fields
* 	  custom_attributes: DEPRECATED - use ca_sta instead
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","geojson","kml","lpk","xlsx"]
   */

  getStationList(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'station_no',
      'station_id',
      'station_uuid',
      'station_name',
      'catchment_no',
      'catchment_id',
      'catchment_name',
      'site_no',
      'site_id',
      'site_uuid',
      'site_name',
      'parametertype_id',
      'parametertype_name',
      'stationparameter_name',
      'object_type',
      'object_type_shortname',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getStationList', query);
  }

  /**
   * Retrieves a list of timeseries with metadata.
   * @param params

 * @param {(String | String[] )} params.station_no ,
 * @param {(String | String[] )} params.station_id ,
 * @param {(String | String[] )} params.station_name ,
 * @param {(String | String[] )} params.ts_id ,
 * @param {String} params.ts_path The timeseries path as definied in WISKI7 - 'site_no/station_no/stationparameter_no/ts_shortname'; A single path can be used with wildcards, or a comma seperated list of paths without wildcards; using the ts_path filter disables all other filters,
 * @param {(String | String[] )} params.ts_name ,
 * @param {(String | String[] )} params.ts_shortname ,
 * @param {(String | String[] )} params.ts_type_id ,
 * @param {(String | String[] )} params.parametertype_id ,
 * @param {(String | String[] )} params.parametertype_name ,
 * @param {(String | String[] )} params.stationparameter_name ,
 * @param {(String | String[] )} params.stationparameter_no ,
 * @param {(String | String[] )} params.ts_unitname ,
 * @param {String} params.timeseriesgroup_id ,
 * @param {String} params.fulltext fulltext filter for all timeseries metadata fields

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.dateformat] the date format that will be returned, see date formats,
 * @param {String} [params.timezone] the time zone that will be returned for all timestamps, any time zone string as understood by Java (e.g. GMT+5, UTC, CET),
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.ca_site_returnfields] selects a set of custom defined site attributes (returnfield 'ca_site' must be active, allows comma separated list),
 * @param {String} [params.ca_sta_returnfields] selects a set of custom defined station attributes (returnfield 'ca_sta' must be active, allows comma separated list),
 * @param {String} [params.ca_par_returnfields] selects a set of custom defined parameter attributes (returnfield 'ca_par' must be active, allows comma separated list),
 * @param {String} [params.ca_ts_returnfields] selects a set of custom defined parameter attributes (md_returnfield 'ca_ts' must be active, allows comma separated list),
 * @param {String} [params.addlinks] adds links for easier data discovery to the response
   * @param {('station_no'|'station_id'|'station_name'|'station_latitude'|'station_longitude'|'station_carteasting'|'station_cartnorthing'|'station_local_x'|'station_local_y'|'station_georefsystem'|'station_longname'|'ts_id'|'ts_name'|'ts_shortname'|'ts_path'|'ts_type_id'|'ts_type_name'|'parametertype_id'|'parametertype_name'|'stationparameter_name'|'stationparameter_no'|'stationparameter_longname'|'ts_unitname'|'ts_unitsymbol'|'ts_unitname_abs'|'ts_unitsymbol_abs'|'site_no'|'site_id'|'site_name'|'catchment_no'|'catchment_id'|'catchment_name'|'coverage'|'ts_density'|'ts_exchange'|'ts_spacing'|'ts_clientvalue##'|'datacart'|'ca_site'|'ca_sta'|'ca_par'|'ca_ts')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  station_no:
* 	  station_id:
* 	  station_name:
* 	  station_latitude:
* 	  station_longitude:
* 	  station_carteasting:
* 	  station_cartnorthing:
* 	  station_local_x:
* 	  station_local_y:
* 	  station_georefsystem:
* 	  station_longname:
* 	  ts_id:
* 	  ts_name:
* 	  ts_shortname:
* 	  ts_path: The timeseries path as definied in WISKI7 - 'site_no/station_no/stationparameter_no/ts_shortname'
* 	  ts_type_id:
* 	  ts_type_name:
* 	  parametertype_id:
* 	  parametertype_name:
* 	  stationparameter_name:
* 	  stationparameter_no:
* 	  stationparameter_longname:
* 	  ts_unitname:
* 	  ts_unitsymbol:
* 	  ts_unitname_abs:
* 	  ts_unitsymbol_abs:
* 	  site_no:
* 	  site_id:
* 	  site_name:
* 	  catchment_no:
* 	  catchment_id:
* 	  catchment_name:
* 	  coverage: will add from/to values of the data coverage
* 	  ts_density: will add an estimation of how many values this timeseries theoretically has per year
* 	  ts_exchange: will add the timeseries exchange ID in case it exists
* 	  ts_spacing: value spacing of equidistant timeseries as ISO period
* 	  ts_clientvalue##: timeseries client values reaching from 1-10, e.g. ts_clientvalue4
* 	  datacart: use this fieldname to retrieve the result as a full 'Datacart', other entries will be ignored if existent
* 	  ca_site: returns custom site attributes, optionally use parameter ca_site_returnfields to select specific fields
* 	  ca_sta: returns custom station attributes, optionally use parameter ca_sta_returnfields to select specific fields
* 	  ca_par: returns custom parameter attributes, optionally use parameter ca_par_returnfields to select specific fields
* 	  ca_ts: returns custom timeseries attributes, optionally use parameter ca_ts_returnfields to select specific fields
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","geojson","kml","lpk","xlsx"]
   */

  getTimeseriesList(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'station_no',
      'station_id',
      'station_name',
      'ts_id',
      'ts_name',
      'ts_shortname',
      'ts_type_id',
      'parametertype_id',
      'parametertype_name',
      'stationparameter_name',
      'stationparameter_no',
      'ts_unitname',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getTimeseriesList', query);
  }

  /**
   * Retrieves timeseries data.
   * @param params

 * @param {(String | String[] )} params.ts_id either ts_id or timeseriesgroup_id or ts_path can be used; one or more transformations may be concatenated to each ID with a semicolon, e.g. ts_id=123;factor(3.0),
 * @param {(String | String[] )} params.timeseriesgroup_id either ts_id or timeseriesgroup_id or ts_path can be used,
 * @param {String} params.ts_path the timeseries path according to WISKI7; a single path with placeholders may be used or a comma separated list without placeholders; either ts_id or timeseriesgroup_id or ts_path can be used; one or more transformations may be concatenated to each path with a semicolon, e.g. ts_path=a/b/c/d;factor(3.0),
 * @param {String} params.from see date formats, not providing 'from' delivers the last ts value only,
 * @param {String} params.to see date formats, using yyyy-MM-dd as format includes the complete given day,
 * @param {String} params.period retrieve data for a time period according to ISO format P#Y#M#DT#H#M#S where P just indicates an arbitrary period with the # being integers; the entities are: Y=Year, M=Month, D=Day, T just indicates that smaller entities are included, H=Hour, M=Minute, S=Second; W=Week is also supported but mutually exclusive with D=Day setting; Examples: P3D, P1Y, P1DT12H, PT6H, P1Y6M3DT4H20M30S; all periods are applied by calendar; as an alternative period can be set to 'complete' to return all data,
 * @param {String} params.changedsince see date formats, will return all data of the timeseries changed since the given date (requires active ts edit history in WISKI)

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.metadata] set metadata=true to add more metadata fields to responses,
 * @param {String} [params.md_returnfields] specify exactly which metadata fields should be returned; available fields: ts_id, ts_path, ts_name, ts_shortname, station_no, station_id, station_latitude, station_longitude, station_carteasting, station_cartnorthing, station_local_x, station_local_y, station_name, stationparameter_name, stationparameter_no, stationparameter_longname, ts_unitname, ts_unitsymbol, ts_unitname_abs, ts_unitsymbol_abs, parametertype_id, parametertype_name, ts_exchange, ts_precision, station_longname, site_id, site_no, site_name, catchment_id, catchment_no, catchment_name, ensembledate, ensembledispatchinfo, timezone (the timezone of the values), ts_spacing, ts_clientvalue## (client values 1-10), river_name, custom_attributes (deprecated - use ca_sta), ca_site (custom site attributes), ca_sta (custom station attributes), ca_par (custom parameter attributes), ca_ts (custom timeseries attributes),
 * @param {String} [params.custattr_returnfields] DEPRECATED - use ca_sta_returnfields instead,
 * @param {String} [params.ca_site_returnfields] selects a set of custom defined site attributes (md_returnfield 'ca_site' must be active, allows comma separated list),
 * @param {String} [params.ca_sta_returnfields] selects a set of custom defined station attributes (md_returnfield 'ca_sta' must be active, allows comma separated list),
 * @param {String} [params.ca_par_returnfields] selects a set of custom defined parameter attributes (md_returnfield 'ca_par' must be active, allows comma separated list),
 * @param {String} [params.ca_ts_returnfields] selects a set of custom defined timeseries attributes (md_returnfield 'ca_ts' must be active, allows comma separated list),
 * @param {String} [params.dateformat] the date format that will be returned, see date formats,
 * @param {String} [params.timezone] the time zone that will be returned for all timestamps, any time zone string as understood by Java (e.g. GMT+5, UTC, CET) or 'individual' to use individual timeseries timezones,
 * @param {String} [params.crs] set crs=webmercator to retrieve position data in webmercator projection (only applies for esrijson format),
 * @param {String} [params.valueorder] sets the order in which the values will be returned, asc/ascending or desc/descending; default is ascending,
 * @param {String} [params.useprecision] advises KiWIS to use the WISKI precision for value formatting, true or false; default is true,
 * @param {String} [params.valuelocale] sets the locale in which the values will be formatted; default is en; for de, csvDiv will automatically be ';', all json formats will use locale en; only applies if useprecision=true,
 * @param {String} [params.valuesasstring] advises KiWIS to return timeseries values in JSON as string instead of number, true or false; default is false,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.getensembletimestampsonly] can only be used if all ts_ids are ensemble timeseries and will return a list of available fore-/hindcast timestamps without values; cannot be used for wml2 format,
 * @param {String} [params.sincefirstchange] only active for the changedsince filter; retrieves all data since the first changed timestamp without gaps
   * @param {('Timestamp'|'Value'|'Interpolation Type'|'Quality Code'|'Quality Code Name'|'Quality Code Description'|'Aggregation Accuracy'|'Absolute Value'|'AV Interpolation Type'|'AV Quality Code'|'AV Quality Code Name'|'AV Quality Code Description'|'Runoff Value'|'RV Interpolation Type'|'RV Quality Code'|'RV Quality Code Name'|'RV Quality Code Description'|'Stagesource Value'|'Occurrance Timestamp'|'Occurrance Count'|'Timeseries Comment'|'Agent Comment'|'Station Comment'|'Parameter Comment'|'Data Comment'|'Release State')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
Please note that transformation results will have fixed automatic returnfields.
   * 	  Timestamp:
* 	  Value: Default value column if present in the timeseries
* 	  Interpolation Type:
* 	  Quality Code:
* 	  Quality Code Name:
* 	  Quality Code Description:
* 	  Aggregation Accuracy: Percentage of not missing/good values for aggregation timeseries
* 	  Absolute Value: Absolute value column if present in the timeseries
* 	  AV Interpolation Type:
* 	  AV Quality Code:
* 	  AV Quality Code Name:
* 	  AV Quality Code Description:
* 	  Runoff Value: Runoff value column if present in the timeseries
* 	  RV Interpolation Type:
* 	  RV Quality Code:
* 	  RV Quality Code Name:
* 	  RV Quality Code Description:
* 	  Stagesource Value: Stagesource value column if present in the timeseries
* 	  Occurrance Timestamp: Time of occurrance for statistical values (e.g. time of the monthly max)
* 	  Occurrance Count: Occurrance count for statistical values
* 	  Timeseries Comment: Any comments written at the timeseries level, separated with a ';'
* 	  Agent Comment: Any comments written at the agent level, separated with a ';'
* 	  Station Comment: Any comments written at the station level, separated with a ';'
* 	  Parameter Comment: Any comments written at the parameter level, separated with a ';'
* 	  Data Comment: Any comments written at the data level, separated with a ';'
* 	  Release State: Any release states written at the data level, separated with a ';'
   * @param {String} format Possible formats as parameter ["ascii","html","csv","dajson","esrijson","wml2","zrxp","xlsx"]
   */

  getTimeseriesValues(params = {}, returnfields, format = 'objson') {
    let CSL = ['ts_id', 'timeseriesgroup_id'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getTimeseriesValues', query, true);
  }

  /**
   * Retrieves a layer of timeseries values for a given date including location data.
   * @param params

 * @param {(String | String[] )} params.ts_id either ts_id, ts_path, timeseriesgroup_id or bbox can be used,
 * @param {String} params.ts_path the timeseries path according to WISKI7; a single path with placeholders may be used or a comma separated list without placeholders; either ts_id, ts_path, timeseriesgroup_id or bbox can be used,
 * @param {(String | String[] )} params.timeseriesgroup_id either ts_id, ts_path, timeseriesgroup_id or bbox can be used,
 * @param {String} params.bbox either ts_id, ts_path, timeseriesgroup_id or bbox can be used; comma separated list with four values in order min_x, min_y, max_x, max_y; use 'crs' parameter to choose between local and global coordinates; fields stationparameter_no and ts_shortname are required for bbox; the function will select 0 or 1 timeseries per station in the area according to filters,
 * @param {(String | String[] )} params.stationparameter_no with bbox filter only; if multiple values are given the order will be considered for timeseries selection,
 * @param {(String | String[] )} params.ts_shortname with bbox filter only; if multiple values are given the order will be considered for timeseries selection,
 * @param {String} params.date the date to request values for, see date formats,
 * @param {String} params.valuecolumn the value column to use for output; valid values are: default, absolute, runoff, stagesource,
 * @param {(String | String[] )} params.site_no can be used to additionally filter for sites

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.metadata] set metadata=true to add more metadata fields to responses,
 * @param {String} [params.md_returnfields] specify exactly which metadata fields should be returned; available fields: ts_id, ts_path, ts_name, ts_shortname, station_no, station_id, station_name, stationparameter_name, stationparameter_no, stationparameter_longname, ts_unitname, ts_unitsymbol, ts_unitname_abs, ts_unitsymbol_abs, parametertype_id, parametertype_name, station_longname, custom_attributes (deprecated - use ca_sta), station_count (adds a field containing the number of duplicate stations per entry), aggaccuracy_percent (adds percentage of not missing/good values for aggregation timeseries), timezone (the timezone of the values), group_item_order (only for one group), site_id, site_no, site_name, catchment_id, catchment_no, catchment_name, object_type, object_type_shortname, ts_clientvalue## (client values 1-10), ca_site (custom site attributes), ca_sta (custom station attributes), ca_par (custom parameter attributes), ca_ts (custom timeseries attributes),
 * @param {String} [params.custattr_returnfields] DEPRECATED - use ca_sta_returnfields instead,
 * @param {String} [params.ca_site_returnfields] selects a set of custom defined site attributes (md_returnfield 'ca_site' must be active, allows comma separated list),
 * @param {String} [params.ca_sta_returnfields] selects a set of custom defined station attributes (md_returnfield 'ca_sta' must be active, allows comma separated list),
 * @param {String} [params.ca_par_returnfields] selects a set of custom defined parameter attributes (md_returnfield 'ca_par' must be active, allows comma separated list),
 * @param {String} [params.ca_ts_returnfields] selects a set of custom defined timeseries attributes (md_returnfield 'ca_ts' must be active, allows comma separated list),
 * @param {String} [params.dateformat] the date format that will be returned, see date formats,
 * @param {String} [params.timezone] the time zone that will be returned for all timestamps, any time zone string as understood by Java (e.g. GMT+5, UTC, CET) or 'individual' to use individual timeseries timezones,
 * @param {String} [params.crs] set to 'localxy', 'regional' or 'global' to use the local, regional or global station reference system, set to 'webmercator' to retrieve position data in webmercator projection (only applies for esrijson format and uses global station coordinates for calculation),
 * @param {String} [params.orderby] sorts the result set by the given field, this can be helpful e.g. for drawing order in a map; available fields: value, timestamp, station_name, station_no, group_item_order (only with one group),
 * @param {String} [params.orderdir] sets the sort direction, only usable together with 'orderby'; possible values: asc, desc; default is ascending,
 * @param {String} [params.invalidperiod] sets the deviation period between requested and returned timestamp to determine which values will be invalidated, must be used together with 'invalidvalue'; can be used like the normal period parameter for other requests,
 * @param {String} [params.invalidvalue] sets the value that will be inserted for invalids, must be used together with 'invalidperiod'; any numeric value can be used,
 * @param {String} [params.showemptytimeseries] sets if timeseries without a value should be removed or shown in the layer; default is true,
 * @param {String} [params.hidetsid] sets if timeseries ids should be hidden or shown in the layer; default is false; DEPRECATED - use returnfields instead,
 * @param {String} [params.useprecision] advises KiWIS to use the WISKI precision for value formatting, true or false; default is true,
 * @param {String} [params.valuelocale] sets the locale in which the values will be formatted; default is en; for de, csvDiv will automatically be ';', all json formats will use locale en; only applies if useprecision=true,
 * @param {String} [params.valuesasstring] advises KiWIS to return timeseries values in JSON as string instead of number, true or false; default is false,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request
   * @param {('ts_id'|'timestamp'|'ts_value'|'req_timestamp'|'ts_intpol'|'q_code'|'q_code_name'|'q_code_desc'|'sta_location')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  ts_id:
* 	  timestamp:
* 	  ts_value:
* 	  req_timestamp:
* 	  ts_intpol: Interpolation Type
* 	  q_code: Quality Code
* 	  q_code_name: Quality Code Name
* 	  q_code_desc: Quality Code Description
* 	  sta_location: Station coordinates with consideration of the crs paramter
   * @param {String} format Possible formats as parameter ["ascii","html","csv","objson","esrijson","geojson","xlsx"]
   */

  getTimeseriesValueLayer(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'ts_id',
      'timeseriesgroup_id',
      'stationparameter_no',
      'ts_shortname',
      'site_no',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getTimeseriesValueLayer', query, true);
  }

  /**
   * Retrieves a list of sites with metadata.
   * @param params

 * @param {(String | String[] )} params.site_no ,
 * @param {(String | String[] )} params.site_id ,
 * @param {(String | String[] )} params.site_uuid ,
 * @param {(String | String[] )} params.site_name ,
 * @param {(String | String[] )} params.parametertype_id ,
 * @param {(String | String[] )} params.parametertype_name ,
 * @param {(String | String[] )} params.stationparameter_name ,
 * @param {String} params.bbox comma separated list with four values in order min_x, min_y, max_x, max_y; use 'crs' parameter to choose between local and global coordinates

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.crs] select which coordinates should be used for bbox filtering, possible values are 'local' and 'global'; default is global,
 * @param {String} [params.custattr_returnfields] DEPRECATED - use ca_site_returnfields instead,
 * @param {String} [params.ca_site_returnfields] selects a set of custom defined site attributes (md_returnfield 'ca_site' must be active, allows comma separated list),
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.addlinks] adds links for easier data discovery to the response
   * @param {('site_no'|'site_id'|'site_uuid'|'site_name'|'site_latitude'|'site_longitude'|'site_carteasting'|'site_cartnorthing'|'site_type_name'|'site_type_shortname'|'parametertype_id'|'parametertype_name'|'stationparameter_name'|'site_georefsystem'|'site_area_wkt'|'site_area_wkt_org'|'ca_site'|'custom_attributes')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  site_no:
* 	  site_id:
* 	  site_uuid: Unique database independent ID
* 	  site_name:
* 	  site_latitude:
* 	  site_longitude:
* 	  site_carteasting:
* 	  site_cartnorthing:
* 	  site_type_name:
* 	  site_type_shortname:
* 	  parametertype_id:
* 	  parametertype_name:
* 	  stationparameter_name:
* 	  site_georefsystem:
* 	  site_area_wkt: the WKT string of the site extent if set
* 	  site_area_wkt_org: the original WKT string of the site extent if set
* 	  ca_site: returns custom site attributes, optionally use parameter ca_site_returnfields to select specific fields
* 	  custom_attributes: DEPRECATED - use ca_site instead
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","geojson","xlsx"]
   */

  getSiteList(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'site_no',
      'site_id',
      'site_uuid',
      'site_name',
      'parametertype_id',
      'parametertype_name',
      'stationparameter_name',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getSiteList', query);
  }

  /**
   * Retrieves a list of parameters available at sites and/or stations.
   * @param params

 * @param {(String | String[] )} params.station_no ,
 * @param {(String | String[] )} params.station_id ,
 * @param {(String | String[] )} params.station_name ,
 * @param {(String | String[] )} params.site_no ,
 * @param {(String | String[] )} params.site_id ,
 * @param {(String | String[] )} params.site_name ,
 * @param {(String | String[] )} params.stationparameter_id ,
 * @param {(String | String[] )} params.stationparameter_name ,
 * @param {(String | String[] )} params.stationparameter_no ,
 * @param {(String | String[] )} params.stationparameter_longname ,
 * @param {(String | String[] )} params.parametertype_id ,
 * @param {(String | String[] )} params.parametertype_name ,
 * @param {(String | String[] )} params.parametertype_longname

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.ca_par_returnfields] selects a set of custom defined parameter attributes (returnfield 'ca_par' must be active, allows comma separated list),
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request
   * @param {('station_no'|'station_id'|'station_name'|'site_no'|'site_id'|'site_name'|'stationparameter_id'|'stationparameter_name'|'stationparameter_no'|'stationparameter_longname'|'parametertype_id'|'parametertype_name'|'parametertype_longname'|'parametertype_shortunitname'|'parametertype_unitname'|'ca_par')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  station_no:
* 	  station_id:
* 	  station_name:
* 	  site_no:
* 	  site_id:
* 	  site_name:
* 	  stationparameter_id:
* 	  stationparameter_name:
* 	  stationparameter_no:
* 	  stationparameter_longname:
* 	  parametertype_id:
* 	  parametertype_name:
* 	  parametertype_longname:
* 	  parametertype_shortunitname:
* 	  parametertype_unitname:
* 	  ca_par: custom parameter attributes
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getParameterList(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'station_no',
      'station_id',
      'station_name',
      'site_no',
      'site_id',
      'site_name',
      'stationparameter_id',
      'stationparameter_name',
      'stationparameter_no',
      'stationparameter_longname',
      'parametertype_id',
      'parametertype_name',
      'parametertype_longname',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getParameterList', query);
  }

  /**
   * Retrieves the system defined parameter type list.
   * @param params

 * @param {(String | String[] )} params.parametertype_id ,
 * @param {(String | String[] )} params.parametertype_name

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request


   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getParameterTypeList(params = {}, format = 'objson') {
    let CSL = ['parametertype_id', 'parametertype_name'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getParameterTypeList', query);
  }

  /**
   * Retrieves a timeseries type list.
   * @param params

 * @param {(String | String[] )} params.ts_type_id ,
 * @param {(String | String[] )} params.ts_shortname ,
 * @param {(String | String[] )} params.ts_name

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request
   * @param {('ts_type_id'|'ts_type_name'|'ts_name'|'ts_shortname')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  ts_type_id:
* 	  ts_type_name:
* 	  ts_name:
* 	  ts_shortname:
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getTimeseriesTypeList(params = {}, returnfields, format = 'objson') {
    let CSL = ['ts_type_id', 'ts_shortname', 'ts_name'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getTimeseriesTypeList', query);
  }

  /**
   * Retrieves a list of timeseries and station groups.
   * @param params

 * @param {(String | String[] )} params.group_name ,
 * @param {String} params.group_type valid values are 'station', 'parameter', 'timeseries' and 'timeseries_dynamic',
 * @param {(String | String[] )} params.supergroup_name

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.ca_group_returnfields] selects a set of custom defined group attributes (md_returnfield 'ca_group' must be active, allows comma separated list),
 * @param {String} [params.addlinks] adds links for easier data discovery to the response
   * @param {('group_name'|'group_id'|'supergroup_id'|'supergroup_name'|'group_type'|'group_remark'|'ca_group')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  group_name:
* 	  group_id:
* 	  supergroup_id:
* 	  supergroup_name:
* 	  group_type:
* 	  group_remark:
* 	  ca_group: returns custom group attributes, optionally use parameter ca_group_returnfields to select specific fields
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getGroupList(params = {}, returnfields, format = 'objson') {
    let CSL = ['group_name', 'supergroup_name'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getGroupList', query);
  }

  /**
   * Retrieves a graph image of timeseries data.
   * @param params

 * @param {(String | String[] )} params.ts_id either ts_id or ts_path can be used,
 * @param {(String | String[] )} params.ext_ts_id additional ts ids for usage with the placeholder mechanism in templates; either ext_ts_id or ext_ts_path can be used,
 * @param {(String | String[] )} params.ts_path either ts_id or ts_path can be used,
 * @param {(String | String[] )} params.ext_ts_path additional ts paths for usage with the placeholder mechanism in templates; either ext_ts_id or ext_ts_path can be used,
 * @param {String} params.from see date formats,
 * @param {String} params.to see date formats, using yyyy-MM-dd as format includes the complete given day,
 * @param {String} params.period retrieve a graph for a time period according to ISO format P#Y#M#DT#H#M#S where P just indicates an arbitrary period with the # being integers; the entities are: Y=Year, M=Month, D=Day, T just indicates that smaller entities are included, H=Hour, M=Minute, S=Second; W=Week is also supported but mutually exclusive with D=Day setting; Examples: P3D, P1Y, P1DT12H, PT6H, P1Y6M3DT4H20M30S; all periods are applied by calendar; as an alternative period can be set to 'complete' to return all data,
 * @param {String} params.width in pixels, 100 - 2000,
 * @param {String} params.height in pixels, 50 - 1500,
 * @param {String} params.template use a stored template for graph design,
 * @param {String} params.templategroup optionally select a group (sub-directory) for a stored template,
 * @param {(String | String[] )} params.showgroups select specific object groups to be visible in the graph; only applies for template graphs and the template must have groups specified,
 * @param {String} params.hidelegend true|false - overwrites or sets the hide-legend flag for template graphs,
 * @param {String} params.overlayinterval set the overlay interval to year, month, week, day or halfyear; only applies for template graphs where the template contains overlaid setting for one timeseries,
 * @param {(String | String[] )} params.overlayslices select the slices that will be shown, e.g. 0,1,2 will show the first three years if interval is year; only applies for template graphs where the template contains overlaid setting for one timeseries

 * @param {String} [params.renderer] supported values: XYTsRenderer, XYAreaRenderer, XYBarRenderer, XYLineAndShapeRenderer, XYStepAreaRenderer, YIntervalRenderer; does not apply for template graphs,
 * @param {String} [params.timezone] the time zone that will be used in graph, any time zone string as understood by Java (e.g. GMT+5, UTC, CET); if a zone is already defined in a template, it will NOT be overriden,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set, default name is generic per request


   * @param {String} format Possible formats as parameter ["jpg","png"]
   */

  getGraph(params = {}, format = 'objson') {
    let CSL = [
      'ts_id',
      'ext_ts_id',
      'ts_path',
      'ext_ts_path',
      'showgroups',
      'overlayslices',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getGraph', query);
  }

  /**
   * Retrieves a graph image of timeseries data based on stations with a template.
   * @param params

 * @param {(String | String[] )} params.station_id ,
 * @param {String} params.from see date formats,
 * @param {String} params.to see date formats, using yyyy-MM-dd as format includes the complete given day,
 * @param {String} params.period retrieve a graph for a time period according to ISO format P#Y#M#DT#H#M#S where P just indicates an arbitrary period with the # being integers; the entities are: Y=Year, M=Month, D=Day, T just indicates that smaller entities are included, H=Hour, M=Minute, S=Second; W=Week is also supported but mutually exclusive with D=Day setting; Examples: P3D, P1Y, P1DT12H, PT6H, P1Y6M3DT4H20M30S; all periods are applied by calendar; as an alternative period can be set to 'complete' to return all data,
 * @param {String} params.width in pixels, 100 - 2000,
 * @param {String} params.height in pixels, 50 - 1500,
 * @param {String} params.template select a stored template for station graph design, mandatory,
 * @param {String} params.templategroup optionally select a group (sub-directory) for a stored template,
 * @param {(String | String[] )} params.showgroups select specific groups to be visible in the graph; only applies for template graphs and the template must have groups specified,
 * @param {String} params.hidelegend true|false - overwrites or sets the hide-legend flag for template graphs,
 * @param {String} params.overlayinterval set the overlay interval to year, month, week, day or halfyear; only applies for template graphs where the template contains overlaid setting for one timeseries,
 * @param {(String | String[] )} params.overlayslices select the slices that will be shown, e.g. 0,1,2 will show the first three years if interval is year; only applies for template graphs where the template contains overlaid setting for one timeseries

 * @param {String} [params.timezone] the time zone that will be used in graph, any time zone string as understood by Java (e.g. GMT+5, UTC, CET); if a zone is already defined in a template, it will NOT be overriden,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set, default name is generic per request


   * @param {String} format Possible formats as parameter ["jpg","png"]
   */

  getStationGraph(params = {}, format = 'objson') {
    let CSL = ['station_id', 'showgroups', 'overlayslices'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getStationGraph', query);
  }

  /**
   * Retrieves a list of stored graph templates with metadata.
   * @param params

 * @param {String} params.templategroup optionally select a group (sub-directory) for stored templates

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name


   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","xlsx"]
   */

  getGraphTemplateList(params = {}, format = 'objson') {
    let CSL = [];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getGraphTemplateList', query);
  }

  /**
   * Retrieves a list of catchments with metadata.
   * @param params

 * @param {(String | String[] )} params.catchment_no ,
 * @param {(String | String[] )} params.catchment_id ,
 * @param {(String | String[] )} params.catchment_name ,
 * @param {(String | String[] )} params.catchment_parent_id

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set, default name is generic per request,
 * @param {String} [params.addlinks] adds links for easier data discovery to the response
   * @param {('catchment_no'|'catchment_id'|'catchment_name'|'catchment_parent_id'|'catchment_size'|'catchment_area_wkt'|'catchment_area_wkt_org')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  catchment_no:
* 	  catchment_id:
* 	  catchment_name:
* 	  catchment_parent_id:
* 	  catchment_size:
* 	  catchment_area_wkt: the WKT string of the catchment polygon if set
* 	  catchment_area_wkt_org: the original WKT string of the site extent if set
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getCatchmentList(params = {}, returnfields, format = 'objson') {
    let CSL = [
      'catchment_no',
      'catchment_id',
      'catchment_name',
      'catchment_parent_id',
    ];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getCatchmentList', query);
  }

  /**
   * Retrieves a list of rivers with metadata.
   * @param params

 * @param {(String | String[] )} params.river_id ,
 * @param {(String | String[] )} params.river_name

 * @param {String} [params.csvdiv] overwrite the standard CSV delimiter (;) with another symbol in CSV requests,
 * @param {String} [params.jsoncallback] return any JSON request as jsonp with the given function name,
 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request
   * @param {('river_id'|'river_name')[]} [returnfields] Returnable fields as optional parameter 'returnfields' (allows comma separated list)
   * 	  river_id:
* 	  river_name:
   * @param {String} format Possible formats as parameter ["ascii","html","csv","tabjson","objson","xlsx"]
   */

  getRiverList(params = {}, returnfields, format = 'objson') {
    let CSL = ['river_id', 'river_name'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    return this._request('getRiverList', query);
  }

  /**
   * Retrieves a list of WISKI color classifications.
   * @param params

 * @param {String} params.name The stationClassification name,
 * @param {String} params.parameterType The parameter type,
 * @param {String} params.classificationType The stationClassification type



   * @param {String} format Possible formats as parameter ["json"]
   */

  getColorClassifications(params = {}, format = 'objson') {
    let CSL = [];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getColorClassifications', query);
  }

  /**
   * Retrieves a list of timeseries release states.
   * @param params

 * @param {(String | String[] )} params.ts_id either ts_id or ts_path can be used,
 * @param {(String | String[] )} params.ts_path the timeseries path according to WISKI7; either ts_id or ts_path can be used,
 * @param {String} params.from see date formats,
 * @param {String} params.to see date formats, using yyyy-MM-dd as format includes the complete given day,
 * @param {String} params.period retrieve data for a time period according to ISO format P#Y#M#DT#H#M#S where P just indicates an arbitrary period with the # being integers; the entities are: Y=Year, M=Month, D=Day, T just indicates that smaller entities are included, H=Hour, M=Minute, S=Second; W=Week is also supported but mutually exclusive with D=Day setting; Examples: P3D, P1Y, P1DT12H, PT6H, P1Y6M3DT4H20M30S; all periods are applied by calendar



   * @param {String} format Possible formats as parameter ["json"]
   */

  getTimeseriesReleaseStateList(params = {}, format = 'objson') {
    let CSL = ['ts_id', 'ts_path'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getTimeseriesReleaseStateList', query);
  }

  /**
   * Retrieves a list of timeseries ensembles with values for one or more timeseries.
   * @param params

 * @param {(String | String[] )} params.ts_id either ts_id, ts_path or timeseriesgroup_id can be used,
 * @param {String} params.ts_path the timeseries path according to WISKI7; a single path with placeholders may be used or a comma separated list without placeholders; either ts_id, ts_path or timeseriesgroup_id can be used,
 * @param {(String | String[] )} params.timeseriesgroup_id either ts_id, ts_path or timeseriesgroup_id can be used,
 * @param {String} params.from see date formats,
 * @param {String} params.to see date formats, using yyyy-MM-dd as format includes the complete given day,
 * @param {String} params.period retrieve data for a time period according to ISO format P#Y#M#DT#H#M#S where P just indicates an arbitrary period with the # being integers; the entities are: Y=Year, M=Month, D=Day, T just indicates that smaller entities are included, H=Hour, M=Minute, S=Second; W=Week is also supported but mutually exclusive with D=Day setting; Examples: P3D, P1Y, P1DT12H, PT6H, P1Y6M3DT4H20M30S; all periods are applied by calendar,
 * @param {String} params.ensembledispatchinfo the dispatch info for which ensembles should be returned

 * @param {String} [params.downloadaszip] provides the result as a ZIP file containing a file in the selected format,
 * @param {String} [params.downloadfilename] sets the file name if downloadaszip=true is set or CSV and Excel downloads are requested, default name is generic per request,
 * @param {String} [params.dateformat] the date format that will be returned, see date formats,
 * @param {String} [params.timezone] the time zone that will be returned for all timestamps, any time zone string as understood by Java (e.g. GMT+5, UTC, CET),
 * @param {String} [params.useprecision] advises KiWIS to use the WISKI precision for value formatting, true or false; default is true


   * @param {String} format Possible formats as parameter ["json"]
   */

  getTimeseriesEnsembleValues(params = {}, format = 'objson') {
    let CSL = ['ts_id', 'timeseriesgroup_id'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    return this._request('getTimeseriesEnsembleValues', query);
  }

  getUrl(params = {}, returnfields, format = 'objson') {
    let CSL = ['group_name', 'supergroup_name'];
    CSL = CSL.concat([]);
    CSL.forEach(key => {
      if (params[key]) {
        params[key] = KIWIS._handleCSL(params[key]);
      }
    });
    const query = { format, ...params };

    if (returnfields) {
      query.returnfields = KIWIS._handleCSL(returnfields);
    }

    const url = new URL(this.basePath);
    url.search = new URLSearchParams({
      request: 'getTimeseriesValues',
      service: this.service,
      type: this.type,
      datasource: this.datasource,
      ...query,
    }).toString();
    return url.toString();
  }

  _request(request, query, nocache) {
    const url = new URL(this.basePath);
    url.search = new URLSearchParams({
      request,
      service: this.service,
      type: this.type,
      datasource: this.datasource,
      ...query,
    }).toString();
    const urlKey = url.toString();
    if (callcache[urlKey] && !nocache) {
      return callcache[urlKey];
    }
    const req = fetch(urlKey).then(resp => {
      if (query.format === 'objson' || query.format === 'json') {
        return resp.json();
      }
      return resp.text();
    });
    callcache[urlKey] = req;
    return req;
  }
}
