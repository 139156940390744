// inject tsData to data in series and value in plotline
// set id for series is better for performance
// TODO write unit test

export default function LinkedTo(clz) {
  return class extends clz {
    static get properties() {
      return {
        visibleFlags: {
          type: Object,
          initial: () => ({}),
        },
      };
    }

    _addLinkedTo(options) {
      const series = options.series || [];
      const _t = this;
      series.forEach(s => {
        // the condition for fake series
        if (typeof s !== 'string') {
          const originHander = s.events?.legendItemClick;
          s.events = {
            ...s.events,
            legendItemClick() {
              originHander && originHander.call(this);
              _t.visibleFlags = { ..._t.visibleFlags, [s.id]: !this.visible };
            },
          };
        }
      });

      if (options.xAxis?.plotLines) {
        options.xAxis.plotLines = this._filterPlotLines(
          options.xAxis.plotLines,
        );
      }

      if (options.yAxis?.plotLines) {
        options.yAxis.plotLines = this._filterPlotLines(
          options.yAxis.plotLines,
        );
      }

      return options;
    }

    _filterPlotLines(plotLines) {
      return plotLines.filter(p => {
        if (p.linkedTo) {
          const vis = this.visibleFlags[p.linkedTo];
          return vis === undefined || vis;
        }
        return true;
      });
    }
  };
}
