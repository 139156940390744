import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import nls from '../locales/index';

@customElement('ww-sample-component')
export default class WwSampleComponent extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    }
  `;

  firstUpdated() {
    this.api = getCurrentApi();
    this.fetchData();
  }

  @property({ type: String })
  content = 'Some random content';

  @property({ type: Object })
  api = getCurrentApi();

  render() {
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  async fetchData() {}
}
