/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Stroke, Style } from 'ol/style';
import Chart from 'ol-ext/style/Chart';
import dayjs from 'dayjs';
import StationsLayer from './StationsLayer';

/** Set in layercfg -> chartConfig -> remarkAttribute */
const hasRemark = function (attr, station) {
  return !!(station[attr] && station[attr] !== '');
};

/** TODO: Apply layerconfig (LANUV-103) */
const handleThreshold = function (item, config) {
  return Math.abs(item.ts_value) < config.chartConfig.threshold
    ? 'rgba(155, 229, 100, 1)'
    : 'rgba(209, 60, 38, 1)';
};

const isOutdated = function (outdatdedCfg, timestamp) {
  const outdated = dayjs(timestamp)
    .add(outdatdedCfg.time, outdatdedCfg.unit)
    .valueOf();
  const now = dayjs().valueOf();
  return outdated < now;
};

export default class StationsChartLayer extends StationsLayer {
  set currentStation(val) {
    this.__currentStation = val;
    //  this.reload();
  }

  static getStationStyle(station, config) {
    const colors = Object.values(
      station.ts_values[config.chartConfig.parameter],
    ).map(item => {
      /** Inactive > Outdated > Threshold */
      if (config.chartConfig.inactive && station.INTERNET_AKTIV === 'no') {
        return '#ffe4e1';
      }
      if (
        config.chartConfig.outdated &&
        !isOutdated(config.chartConfig.outdated, item.timestamp) &&
        !config.chartConfig.threshold
      )
        return 'rgba(52,164,235, 1)';
      if (config.chartConfig.outdated && !config.chartConfig.threshold)
        return 'rgba(209, 60, 38, 1)';
      if (
        config.chartConfig.outdated &&
        isOutdated(config.chartConfig.outdated, item.timestamp) &&
        config.chartConfig.threshold
      )
        return `rgba(128, 128, 128, 1)`;
      if (config.chartConfig.threshold) {
        return handleThreshold(item, config);
      }
      if (station.__tag === 'none') {
        return 'rgba(128, 128, 128, 1)';
      }
    });

    const remarkAttribute = config?.chartConfig?.remarkAttribute;

    const currentStationStyle = new Style({
      image: new Chart({
        colors,
        type: 'pie',
        radius: 10,
        data: colors.map(() => 1),
        rotateWithView: true,
        stroke: new Stroke({
          color: hasRemark(remarkAttribute, station) ? '#000' : '#333',
          width: hasRemark(remarkAttribute, station) ? 3 : 1,
        }),
      }),
      zIndex: 100,
    });

    currentStationStyle.sortPriority = colors.filter(
      x => x === 'rgba(209, 60, 38, 1)',
    ).length;

    return currentStationStyle;
  }

  // eslint-disable-next-line class-methods-use-this
  _getStationStyle(station) {
    return StationsChartLayer.getStationStyle(station, this.config);
  }

  set tagMarkers(val) {
    this.__tagMarkers = val;
    this.reload();
  }

  get tagMarkers() {
    return this.__tagMarkers;
  }
}
