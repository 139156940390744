import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import RasterControl from './RasterControl';

@customElement('ki-station-map-raster-control')
export default class kiStationMapRasterControl extends _AddOn {
  constructor() {
    super();
    this.displayInLayerControl = false;
  }

  static get properties() {
    return {
      label: { type: String },
      displayInLayerControl: { type: Boolean },
    };
  }

  _create() {
    this.rc = new RasterControl({
      name: 'Raster Control',
      displayInLayerControl: this.displayInLayerControl,
      group: 'Information',
      label: this.label,
      projections: this.projections,
      map: this.map,
    });
    this.map.addControl(this.rc);
  }

  _tear() {
    this.map.removeControl(this.rc);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
