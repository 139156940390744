import MousePosition from 'ol/control/MousePosition';
import { format } from 'ol/coordinate';
import proj4 from '../../common/projections';

export default class MultiMousePosition extends MousePosition {
  constructor(options) {
    super(options);
    const self = this;
    this.info = document.createElement('div');
    this.info.className = 'info-box';
    const select = document.createElement('select');
    this.projections = options.projections || ['EPSG:4326'];
    this.projections = options.projections.filter(item => !!proj4(item));
    this.projections.forEach(item =>
      select.appendChild(new Option(proj4(item).oProj.title || item, item)),
    );
    if (this.projections.length < 2) {
      select.style.appearance = 'none';
    }
    [this.currentProj] = this.projections;
    this.element.appendChild(select);
    this.element.appendChild(this.info);
    this.formatter = options.formatter || {
      default: { template: 'x: {x} / y: {y}', decimals: 0 },
      'EPSG:4326': { template: 'long: {x} / lat: {y}', decimals: 4 },
    };
    select.addEventListener('change', e => {
      self.currentProj = e.target.value;
    });
  }

  /* eslint-disable-next-line class-methods-use-this */
  handleMouseOut = function () {};

  updateHTML_ = function (pixel) {
    let html = this.undefinedHTML_;
    if (pixel && this.mapProjection_) {
      const map = this.getMap();
      let coordinate = map.getCoordinateFromPixelInternal(pixel);
      if (coordinate) {
        coordinate = proj4(
          this.mapProjection_.getCode(),
          this.currentProj,
          coordinate,
        );
        const template =
          this.formatter[this.currentProj]?.template ||
          this.formatter.default?.template;
        const decimals =
          this.formatter[this.currentProj]?.decimals ||
          this.formatter.default?.decimals;
        if (template) {
          html = format(coordinate, template, decimals);
          this.info.innerHTML = html;
        }
      }
    }
  };
}
