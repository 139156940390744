import { css, html } from 'lit';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';

export default function ListMode(clz) {
  return class extends clz {
    // language=CSS
    static styles = css`
      :host(.sm-screen) .row {
        display: table;
        width: 100%;
        padding: 10px;
      }

      :host(.sm-screen) .inner-row {
        display: table-row;
        font-size: 14px;
        width: 100%;
      }

      :host(.sm-screen) .label.cell {
        width: 45%;
        font-weight: 600;
      }

      :host(.sm-screen) .cell {
        text-align: left !important;
        white-space: normal !important;
      }

      :host {
        height: 100%;
      }

      :host(.sm-screen) .header .row {
        display: flex;
        box-sizing: border-box;
        padding: 0;
      }

      :host(.sm-screen) .header .row .cell {
        flex: 1;
      }

      ui5-select {
        overflow: hidden;
        width: 100%;
        margin: 0;
        border: none;
        height: 35px !important;
        background: white;
      }

      .col-selector {
        padding: 0;
      }
    `;

    static get properties() {
      return {
        renderListItem: {
          type: Function,
        },
      };
    }

    _renderHeader() {
      if (this.classList.contains('sm-screen')) {
        const sorted = this.sort[0] || {};
        const cols = this.renderingColumns.filter(c => c.sortable);
        const sortedCol = cols.find(col => col.field === sorted.field) || {
          field: this.renderingColumns[0].field,
        };
        const changeColumn = e => {
          const col = cols.find(c => c.field === e.target.selectedOption.value);
          this.sort = [{ ...sorted, field: col.field, sortBy: col.sortBy }];
        };
        const changeOrder = e => {
          this.sort = [
            {
              ...sorted,
              ascending: e.target.selectedOption.value === 'Asc',
            },
          ];
        };

        const getOptions = item =>
          item.field === sortedCol.field
            ? html`<ui5-option selected value="${item.field}"
                >${item.label}</ui5-option
              >`
            : html`<ui5-option value="${item.field}"
                >${item.label}</ui5-option
              >`;

        // language=html
        return html`
          <div class="header">
            <div class="row">
              <div class="cell">
                <div class="header-content">${this.i18n.t('sortBy')}:</div>
              </div>
              <div class="cell">
                <div class="header-content col-selector">
                  <ui5-select placeholder="Column" @change="${changeColumn}"
                    >${cols.map(item => getOptions(item))}</ui5-select
                  >
                </div>
              </div>
              <div class="cell">
                <div class="header-content col-selector">
                  <ui5-select
                    .value="${sorted.ascending ? 'Asc' : 'Desc'}"
                    placeholder="Order"
                    @change="${changeOrder}"
                  >
                    <ui5-option value="Asc">${this.i18n.t('asc')}</ui5-option>
                    <ui5-option value="Desc">${this.i18n.t('desc')}</ui5-option>
                  </ui5-select>
                </div>
              </div>
            </div>
          </div>
        `;
      }
      return super._renderHeader();
    }

    renderRow(item) {
      if (this.classList.contains('sm-screen')) {
        return html`
          <div
            class="row"
            @click="${() => {
              this._onRowClick(item);
            }}"
          >
            ${this.renderListItem(item)}
          </div>
        `;
      }
      return super.renderRow(item);
    }

    renderListItem(item) {
      return html`
        ${this.renderingColumns.map(
          col => html`
            <div class="inner-row">
              <div class="cell label">${col.label}</div>
              ${this._renderCell(col, item)}
            </div>
          `,
        )}
      `;
    }
  };
}
