import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Mix } from '@kisters/wcp-base/common';
import { queryParamMixin } from '@kisters/wcp-base/decorators';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { getCurrentApi } from '@kisters/wiski-web/api';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { customElement } from 'lit/decorators.js';
import { filter } from 'lodash-es';

@customElement('ww-documents')
export default class WwDocuments extends Mix(LitElement, [
  queryParamMixin,
  { targetProperty: 'selected', selector: 'selected', defaultValue: '' },
]) {
  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }

    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
    this.data = [];
  }

  static get properties() {
    return {};
  }

  _selected = '';

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.requestUpdate();
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);
    const baseDir = params.options?.directory ?? 'documents';
    this.directory = `${baseDir}/${params.content}`;

    this.fetchData();
  }

  // Fetch report
  async fetchData() {
    const res = await this.api._requestJson(`/${this.directory}/index.json`);
    this.data = res.data;

    // Filter reports; only allow visible PDF
    this.data = filter(
      this.data,
      d => d.mimeType === 'application/pdf',
    ).reverse(); // Newest file on first position
    this._setDocument(this.data[0].name);
    this.requestUpdate();
  }

  getUrl(file) {
    return `${this.api.dataPath}/${this.directory}/${file}`;
  }

  _setDocument(pdf) {
    const pdfurl = `${this.api.dataPath}/${this.directory}/${pdf}`;
    this.renderRoot.querySelector(
      '#contentframe',
    ).src = `./pdfjs/web/viewer.html?file=${
      new URL(pdfurl, window.location.origin).href
    }`;
  }

  _handleChanged(e) {
    this._setDocument(e.target.selectedOption.value);
  }

  render() {
    // language=html
    return this.data
      ? html`
          <div class="left-panel">
            <ui5-select @change="${this._handleChanged}">
              ${this.data.map(
                item =>
                  html`<ui5-option value="${item.name}"
                    >${item.name.replace('.pdf', '')}</ui5-option
                  >`,
              )}
            </ui5-select>
          </div>
          <div class="right-panel">
            <iframe title="content" id="contentframe"></iframe>
          </div>
        `
      : html`<div>Loading...</div>`;
  }
}
