/* eslint-disable class-methods-use-this */
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import Highcharts from 'highcharts/highstock';
import { groupBy, find } from 'lodash-es';
import hclocales from '@kisters/wcp-water/common/highcharts-locales.js';
import nls from '../../locales/index';
import { getCurrentApi } from '../../api/API';

@customElement('ww-map-popup-precip')
export default class WwMapPopupPrecip extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }
    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }
    .label {
      color: #4a4a49;
    }

    #chartcontainer {
      height: 200px;
      min-width: 360px;
      width: 100%;
    }

    .remark {
      padding-left: 10px;
      color: red;
      max-width: fit-content;
    }
  `;

  @property({ type: Array })
  stations: Array<object> = [];

  @property({ type: String })
  stationRemark; // Additional comment in left Menu

  constructor() {
    super();
    Highcharts.setOptions({
      lang: hclocales[this.i18n.language.substring(0, 2)],
    });
    this.stations = [];
    this.api = getCurrentApi();
    const formatter = new Intl.NumberFormat(this.i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.valFormatter = {
      format(val) {
        return val === 0 ? val : formatter.format(val);
      },
    };
  }

  firstUpdated() {
    this.chart = Highcharts.chart(
      this.renderRoot.querySelector('#chartcontainer'),
      {
        chart: {
          animation: false,
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: [
          {
            id: 'mainYAxis',
            title: {
              text: '',
            },
          },
          {
            id: 'sumYAxis',
            title: {
              text: '',
            },
            opposite: true,
          },
        ],
        plotOptions: {
          series: {
            animation: false,
            label: {
              enabled: false,
            },
          },
        },
        series: [
          {
            id: 'preview',
            type: 'scatter',
            data: [],
            label: {
              enabled: false,
            },
          },
          {
            id: 'linehelper',
            type: 'scatter',
            data: [],
            showInLegend: false,
            marker: {
              enabled: false,
            },
            enableMouseTracking: false,
          },
          {
            id: 'sumline',
            type: 'line',
            data: [],
            showInLegend: false,
            enableMouseTracking: false,
            step: 'left',
            marker: { enabled: false },
            color: 'rgb(170,70,67)',
            yAxis: 'sumYAxis',
          },
        ],
      },
    );
  }

  /* eslint-disable-next-line class-methods-use-this */
  getSeries(data, tsShortname) {
    const ser = find(data, { ts_shortname: tsShortname }) || data[0];
    if (ser) {
      ser.data = ser.data.map(item => [new Date(item[0]).getTime(), item[1]]);
    }
    return ser;
  }

  async _setChartData(station) {
    if (this.chart && this.lastStationId !== station.station_id) {
      this.chart.showLoading();
      const series = this.chart.get('preview');
      const sumSeries = this.chart.get('sumline');
      sumSeries?.setData([]);
      series.setData([]);
      this.lastStationId = station.station_id;
      const siteNo = station.site_no || station.ts_path?.split('/')[0] || 0;
      const stationparameterNo =
        station.stationparameter_no || station.ts_path.split('/')[2];
      const data = await this.api.getTsData(
        `internet/stations/${siteNo}/${station.station_no}/${stationparameterNo}/week.json`,
        false,
      );
      const sdata = this.getSeries(data, station.ts_shortname).data;
      series.update({ type: 'bar', data: sdata }, false);

      const sumData = this._sumLine(sdata);
      sumSeries.setData(sumData);

      this.chart.hideLoading();
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  _getRow(station) {
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter.format(station.ts_value)} ${
            station.ts_unitsymbol
          }`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';
    return html`<div class="row">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  _stationTmpl(stations) {
    const station = stations[0];
    if (this.stations.length < 2) {
      this._setChartData(station);
    }
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">${stations.map(st => this._getRow(st))}</div>
      <div
        id="chartcontainer"
        style="display:${this.stations.length < 2 ? 'block' : 'none'}"
      ></div>`;
  }

  _sumLine(data: Array<[string, number]>): Array<[string, number]> {
    const sumlineData: Array<[string, number]> = [];
    let sum: number = 0;
    data.forEach(d => {
      sum += d[1];
      const t: [string, number] = [d[0], sum];
      sumlineData.push(t);
    });
    return sumlineData;
  }

  render() {
    return html`${Object.values(groupBy(this.stations, 'station_id')).map(
      station => this._stationTmpl(station),
    )}`;
  }
}
