import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Mix } from '@kisters/wcp-base/common';
import { queryParamMixin } from '@kisters/wcp-base/decorators';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { getCurrentApi } from '@kisters/wiski-web/api';

import '@ui5/webcomponents/dist/DatePicker';
import { customElement, query } from 'lit/decorators.js';
import { filter, last } from 'lodash-es';
import dayjs from '@kisters/wcp-base/common/dayjsext';

@customElement('wwp-hdoe-bericht-wasserstand')
export default class WwReport extends Mix(LitElement, [
  queryParamMixin,
  { targetProperty: 'selected', selector: 'selected', defaultValue: '' },
]) {
  @query('#datepicker')
  datepicker;

  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }

    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
    this.data = [];
  }

  static get properties() {
    return {
      data: { type: Array },
    };
  }

  _selected = '';

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.requestUpdate();
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);
    this.content = params.content;
    // Usually "documents" or "reports"
    this.directory = params.options?.directory || 'documents';

    this.fetchData();
  }

  // Fetch report
  async fetchData() {
    const res = await this.api._requestJson(`/${this.directory}/index.json`);
    this.data = res.all_listing;

    // Filter reports; only allow visible PDF
    this.data = filter(this.data, d => {
      if (d.mimeType === 'application/pdf' && d.name.includes(this.content)) {
        const pathArray = d.name.split('/');
        const date = dayjs(
          pathArray[pathArray.length - 1]
            .replace('Wasserstandsbericht-', '')
            .replace('.pdf', ''),
          'YYYY-MM-DD_hh-mm',
        );
        if (date.toString() === 'Invalid Date') {
          return false;
        }
        d.filename = last(pathArray);
        d.value = date.valueOf();
        d.label = date.format('DD.MM.YYYY');
        return true;
      }
      return false;
    }).reverse(); // Newest file on first position
    this.requestUpdate();
  }

  updated() {
    this.data[0] &&
      this._handleChanged({
        target: {
          selectedOption: { value: this.data[0].date },
        },
      });
  }

  getUrl(file) {
    return `${this.api.dataPath}/${this.directory}/${this.content}/${file}`;
  }

  _handleChanged(e) {
    const value = e?.detail?.value ? e?.detail?.value : this.data[0].label;
    const doc = this.data.find(item => item.label === value);
    if (doc) {
      const pdfurl = `${this.api.dataPath}/${doc.name}`;
      this.renderRoot.querySelector(
        '#contentframe',
      ).src = `./pdfjs/web/viewer.html?file=${
        new URL(pdfurl, window.location.origin).href
      }`;
    } else {
      this.renderRoot.querySelector('#contentframe').src = `./html/leer.html`;
    }
  }

  render() {
    // language=html
    return this.data.length
      ? html`
          <div class="left-panel">
            <ui5-date-picker
              id="datepicker"
              max-date="${dayjs().format('DD.MM.YYYY')}"
              format-pattern="dd.MM.yyyy"
              value="${this.data[0].label}"
              @change="${this._handleChanged}"
            >
            </ui5-date-picker>
          </div>
          <div class="right-panel">
            <iframe title="content" id="contentframe"></iframe>
          </div>
        `
      : html`<div>Lade...</div>`;
  }
}
