import Control from 'ol/control/Control';
/*
 * switch legend and set to all layers.
 * */
export default class PopupSwitcher extends Control {
  constructor(params = {}) {
    params = { visible: true, ...params };
    params.element = document.createElement('div');
    params.element.part = 'popup';
    super(params);
    this.setVisible(params.visible);
    this.addstyle = params.addstyle;
    this.setProperties({
      name: params.name,
      displayInLayerControl: params.displayInLayerControl,
      group: params.group,
    });
  }

  set description(description) {
    this.element.description = description;
  }

  set label(label) {
    this.element.label = label;
  }

  set addstyle(style) {
    this.element.addstyle = style;
  }

  set displayInLayerControl(visible) {
    this.setProperties({ displayInLayerControl: visible });
  }

  setMap(map) {
    super.setMap(map);
    // TODO  update flow
  }

  // move a parent class
  setVisible(flag) {
    this._visible = flag;
    this.element.classList.toggle('hide', !flag);
    const map = this.getMap();
    if (map) {
      map.disablePopup = !flag;
    }
  }

  getVisible() {
    return this._visible;
  }
}
