import { customElement } from 'lit/decorators.js';
import { unByKey } from 'ol/Observable';
import _AddOn from './_AddOn';
import JSONLayer from './JSONLayer';

@customElement('ki-json-layer')
export default class KiJsonLayer extends _AddOn {
  static get properties() {
    return {
      styles: { type: Object },
      visible: { type: Boolean },
      name: { type: String },
      url: { type: String },
      group: { type: String },
      projection: { type: String },
    };
  }

  _create() {
    console.time('_create of catchments layer start');
    this._createLayer();
    console.timeEnd('_create of catchments layer end');
  }

  _createLayer() {
    this.catchmentsLayer = new JSONLayer({
      styles: this.styles,
      url: this.url,
      projection: this.projection,
      visible: this.visible,
      name: this.name || 'Catchments',
      displayInLayerControl: true,
      group: this.group || 'Layers',
    });

    this.catchmentsLayer.setZIndex(
      Number(this.getAttribute('zindexlayer')) || 50,
    ); // TODO use PropertyDefaultValue, check why atribute not works

    this.map.addLayer(this.catchmentsLayer);

    this.clickEvent = this.map.on('singleclick', evt => {
      let catchment;
      this.map.forEachFeatureAtPixel(
        evt.pixel,
        (feature, layer) => {
          if (layer === this.catchmentsLayer) {
            if (feature && feature.getId() !== undefined) {
              catchment = feature.catchment;
              // eslint-disable-next-line camelcase
              this.dispatchEvent(
                new CustomEvent('catchment-click', {
                  detail: { value: feature.getId() },
                }),
              );
            }
          }
        },
        { layerFilter: layer => layer === this.catchmentsLayer },
      );
      if (catchment) {
        return false; // skip rest listener;
      }
      return true;
    });
  }

  _initHover() {
    const element = document.createElement('ki-catchment-info');

    this.hoverEvent = this.map.on('pointermove', evt => {
      if (!this.catchmentsLayer) {
        return;
      }

      let hovering;

      this.map.forEachFeatureAtPixel(
        evt.pixel,
        feature => {
          if (feature && feature.catchment) {
            this.map.getTargetElement().style.cursor = 'pointer';
            element.catchment = feature.catchment;

            this.map.dispatchEvent({
              type: 'show-popup',
              detail: {
                coordinate: this.map.getCoordinateFromPixel(evt.pixel),
                element,
              },
            });
            // hovering
            if (!feature.__bak_style) {
              feature.__bak_style = feature.getStyle();
              feature.setStyle(
                this.catchmentsLayer._getHoverStyle(feature.catchment),
              );
            }
            hovering = feature;
          }
        },
        { layerFilter: layer => layer === this.catchmentsLayer },
      );

      this.catchmentsLayer
        .getSource()
        .getFeatures()
        .forEach(f => {
          if (f !== hovering && f.__bak_style) {
            f.setStyle(f.__bak_style);
            f.__bak_style = null;
          }
        });

      if (hovering) {
        // eslint-disable-next-line consistent-return
        return false;
      }
    });
  }

  _tear() {
    this.map.removeLayer(this.catchmentsLayer);
    unByKey(this.clickEvent);
    unByKey(this.hoverEvent);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    if (_changedProperties.has('styles') && this.catchmentsLayer) {
      this.catchmentsLayer.styles = this.catchmentsStyles;
    }
    if (_changedProperties.has('url') && this.catchmentsLayer) {
      this.catchmentsLayer.url = this.catchmentFeatures;
    }
    if (_changedProperties.has('name') && this.catchmentsLayer) {
      this.catchmentsLayer.name = this.catchments;
    }

    if (this.catchmentsLayer && _changedProperties.has('currentCatchment')) {
      // TODO
      this.catchmentsLayer.highlight(this.currentCatchment);
    }
  }
}
