import { css } from 'lit';
// language=CSS
export default css`
  :host {
    position: relative;
    display: flex;
    height: 100%;
    flex: 1;
    overflow: auto;
    --ui5-listitem-border-bottom: none;
  }

  :host .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid lightgray;
    position: relative;
    min-width: 270px;
    max-width: 300px;
  }

  .icon-btn.back-btn-mobile{
    height: 30px;
    fill: var(--theme-color-primary, gray);
    margin-left: 10px;
    font-size: 2.5em;
  }

  :host(.sm-screen) {
    overflow: hidden;
  }

  .hidden {
    display: none;
  }

  .map-container {
    height: 200px;
    flex: 0 0 200px;
  }

  ki-station-map {
    height: 100%;
    width: 100%;
    display: block;
    pointer-events: none;
  }

  .left .list-container {
    flex: 1;
    overflow-y: auto;
  }

  .prevenxt-container {
    display: none;
    flex-direction: row;
  }

  .prevnext-title-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :host .main {
    flex: 3;
    position: relative;
    height: 100%;
    overflow: auto;
  }

  .header {
    z-index: 1;
    padding: 5px;
    display: flex;
    height: 200px;
    position: absolute;
    width: 100%;
    background: linear-gradient(white, transparent);
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
  }

  .icon-btn {
    cursor: pointer;
    font-size: 3em;
    fill: gray;
  }

  .icon-btn:hover {
    color: #3c3c3c;
  }

  .back-btn {
    pointer-events: all;
    font-size: 5em;
    position: absolute;
    left: -15px;
    bottom: 0px;
  }

  .fav-navbtn {
    display: none;
  }

  .title {
    font-size: 1.2em;
    display: inline-block;
    flex: 1;
    color: #3c3c3c;
  }

  .title span {
    padding-left: 10px;
  }

  .fav {
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 2.3em;
  }

  ui5-select {
    width: 250px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    position: absolute;
    width: 85%;
  }

  .status-bar {
    padding: 10px;
    color: red;
    border-bottom: 1px solid lightgray;
  }

  .status-bar a {
    color: unset;
  }

  ki-list {
    border-top: 1px solid gray;
  }

  ki-list-item {
    cursor: pointer;
    padding: 12px 16px;
    color: var(--theme-text-color, #1d1d1b);
    padding-left: 15px !important;
  }

  ki-list-item.selected {
    background: linear-gradient(
      90deg,
      var(--theme-color-primary) 5px,
      rgb(255, 255, 255) 0%
    );
    font-weight: 600;
  }

  ki-list-item:hover {
    background: linear-gradient(
      90deg,
      var(--theme-color-primary) 5px,
      var(--theme-color-secondary) 0%
    );
    color: var(--theme-color-tertiary, white);
  }

  img {
    max-height: 100%;
    max-width: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  ui5-side-navigation {
    width: 100%;
    border: none;
  }

  :host(.sm-screen) .main {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    background: white;
    transition: left 0.5s;
  }

  :host(.sm-screen) .main.hide {
    left: 100%;
  }

  :host .main .graph-title {
    display: none;
  }

  :host(.sm-screen) .main .graph-title {
    display: flex;
    padding: 0;
    position: absolute;
    z-index: 4;
    height: 50px;
    align-items: center;
  }

  :host(.sm-screen) .main.hide .graph-title {
    display: none;
  }

  :host(.sm-screen) .left {
    flex: 0 0 100%;
    display: flex;
    padding: 10px 0;
    max-width: initial;
  }

  ki-station-map::part(overview-map) {
    width: 30%;
    height: 50%;
    bottom: 5px;
    right: 5px;
    pointer-events: none;
    box-shadow: 0 0 3px 3px #fff;
    opacity: 0.9;
    border-radius: 10px;
  }

  :host(.sm-screen.LANDSCAPE) .left {
    flex-direction: row;
  }

  :host(.sm-screen.LANDSCAPE) .left .header {
    width: 30vw;
    height: initial;
  }

  :host(.sm-screen.LANDSCAPE) .left .list-container {
    height: initial;
    flex: 2;
  }

  :host(.sm-screen.LANDSCAPE) .left .map-container {
    height: initial;
    flex: 1;
  }

  :host(.sm-screen.LANDSCAPE) .left ki-list {
    border-top: none;
  }


`;
