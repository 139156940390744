import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import TopoJSON from 'ol/format/TopoJSON';

const style = new Style({
  fill: new Fill({
    color: 'rgba(122, 122, 122, 0.2)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
});

export default class JsonLayer extends VectorLayer {
  constructor(options) {
    const type = options.url
      ? {
          format: new TopoJSON({
            dataProjection: options.projection || 'EPSG:3857',
          }),
          url: options.url,
        }
      : { features: options.features };
    options = {
      source: new VectorSource(type),
      ...options,
    };
    super(options);

    this.imageRatio = 2;
    this.style = options.style || style;
    this.setProperties({
      name: options.name,
      displayInLayerControl: options.displayInLayerControl,
      group: options.group,
    });
  }
}
