import { css } from 'lit';

// language=CSS
export default css`
  :host {
    display: inline-block;
    color: gray;
    font-size: 14px;
    text-align: center;
    padding: 0;
    position: relative;
    background: transparent;
    pointer-events: all;
    z-index: 10;
    --ui5-listitem-selected-border-bottom: none;
    --sapList_SelectionBackgroundColor: white;
    --sapList_AlternatingBackground: white;
    --_ui5-tree-indent-step: 0;
  }

  ui5-checkbox {
    display: none;
  }

  .toggle-btn {
    line-height: 36px;
    font-size: 20px;
    position: relative;
  }

  :host(.list-visible) {
    height: 100%;
  }
  :host(.list-visible) .toggle-btn {
    background: var(--theme-color, #0056a0);
    fill: white;

    z-index: 2;
  }

  :host(.list-visible) ki-icon::part(icon) {
    fill: white;
  }

  .title,
  .basetitle {
    font-weight: bold;
    text-decoration: underline;
  }

  .basetitle {
    padding-left: 55px;
  }

  .layer-list {
    position: absolute;
    background: white;
    right: 0;
    top: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 200px;
    text-align: left;
    display: block;
    visibility: hidden;
    transform: scale(0.8);
    transform-origin: top right;
    opacity: 0;
    transition: transform 0.3s linear 0s, opacity 0.3s linear 0s,
      visibility 0.3s linear 0s;
    border-radius: 18px;
    padding: 10px;
  }

  :host(.list-visible) .layer-list {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    overflow: auto;
    max-height: calc(100% - 220px);
  }

  :host(.sm-screen) .layer-list {
    visibility: visible !important;
    opacity: 1 !important;
    transform: scale(1) !important;
    position: relative;
    top: unset;
    left: unset;
    border-radius: 0;
  }

  .layer-item {
    white-space: nowrap;
    height: 40px;
  }

  .layer-item.radio {
    white-space: nowrap;
    height: 30px;
  }

  ki-checkbox {
    padding: 3px 15px;
  }

  .layer-item .label {
    display: inline-block;
    vertical-align: top;
    line-height: 25px;
    background: white;
    padding: 0px 5px;
  }

  .layer-item.radio .label {
    line-height: 30px;
  }

  hr {
    margin: 10px;
  }

  .radiobutton {
    transform: scale(1.5);
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 8px;
  }

  .colorlegend {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width: 20px;
    height: 3px;
  }
`;
