/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';

/* eslint-disable import/no-extraneous-dependencies */
import '@ui5/webcomponents/dist/Popover';
import '@ui5/webcomponents/dist/List';
import '@ui5/webcomponents/dist/ListItem';
/* eslint-enable import/no-extraneous-dependencies */

import { getQueryObject } from '../../decorators/query';
import { i18nMixin } from '../../decorators/i18n';

import nls from '../../locales/index';

interface Speed {
  label: string;
  value: number;
}

// @ts-expect-error
@customElement('ki-player-speed-chooser')
export default class KiPlayerSpeedChooser extends i18nMixin(LitElement, {
  nls,
}) {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: white;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      position: relative;
    }
    .speed-chooser {
      cursor: pointer;
    }
    .closepopup {
      text-decoration: underline;
      cursor: pointer;
    }
    .speed-popup {
      cursor: default;
    }
  `;

  /**
   * Playback speed.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  speed: number = 1;

  @property({ type: Array, attribute: false })
  speeds: Array<Speed> = [
    {
      label: '2x',
      value: 2,
    },
    {
      label: '1.75x',
      value: 1.75,
    },
    {
      label: '1.5x',
      value: 1.5,
    },
    {
      label: '1.25x',
      value: 1.25,
    },
    {
      label: '1x',
      value: 1,
    },
    {
      label: '0.75x',
      value: 0.75,
    },
    {
      label: '0.5x',
      value: 0.5,
    },
    {
      label: '0.25x',
      value: 0.25,
    },
  ];

  @property({ type: Boolean, attribute: false })
  popupOpened: boolean = false;

  get chooser() {
    return getQueryObject(this, '.speed-chooser');
  }

  get popup() {
    return getQueryObject(this, '.speed-popup');
  }

  constructor() {
    super();
    this.addEventListener('click', this.togglePopup);
  }

  dispatchSpeedChange(newSpeed: number): void {
    this.dispatchEvent(
      new CustomEvent('speedChange', {
        detail: { newSpeed },
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`
      <div class="speed-chooser">${this.speed}x</div>

      <ui5-popover class="speed-popup" header-text="Playback Speed">
        <div class="popover-content">
          <ui5-list
            id="country-selector"
            mode="SingleSelect"
            @selection-change="${this.selectionChanged}"
          >
            ${this.speeds.map(speed => this._renderListItem(speed))}
          </ui5-list>
        </div>
      </ui5-popover>
    `;
  }

  _renderListItem(item: Speed) {
    return html`<ui5-li .selected="${this.speed === item.value}"
      >${item.label}</ui5-li
    >`;
  }

  selectionChanged(e) {
    const { selectedItems } = e.detail;
    if (!selectedItems || selectedItems.length <= 0) {
      return;
    }

    const selectedText = selectedItems[0].textContent;
    const selectedSpeed = this.speeds.find(
      speed => speed.label === selectedText,
    );
    if (!selectedSpeed) {
      return;
    }
    this.speed = selectedSpeed.value;
    this.dispatchSpeedChange(this.speed);
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  openPopup() {
    this.popupOpened = true;
    this.popup.showAt(this.chooser);
  }

  closePopup() {
    this.popupOpened = false;
    this.popup.close();
  }
}
