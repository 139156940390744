import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import LayersControl from './LayersControl';
import './ki-layers-control-element';

@customElement('ki-layers-control')
export default class KiLayersControl extends _AddOn {
  // TODO i18n?

  constructor() {
    super();
    this.cachePrefix = 'none';
  }

  static get properties() {
    return {
      groups: { type: Array },
      i18n: { type: Object },
      style: { type: String },
      context: { type: String },
      cachePrefix: { type: String },
      toggleAll: { type: Boolean },
      treeList: { type: Boolean },
    };
  }

  _create() {
    this.controller = new LayersControl({
      toggleAll: this.toggleAll,
      treeList: this.treeList,
      i18n: this.i18n,
      groups: this.groups,
      style: this.style,
      context: this.context,
      cachePrefix: this.cachePrefix,
    });
    this.map.addControl(this.controller);
  }

  _tear() {
    this.map.removeControl(this.controller);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update. for simple component can just recreate.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
