import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import LocationControl from './LocationControl';

@customElement('ki-location-control')
export default class KiLocationControl extends _AddOn {
  _create() {
    this.locationControl = new LocationControl();
    this.map.addControl(this.locationControl);
  }

  _tear() {
    this.map.removeControl(this.locationControl);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
