import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import nls from '../../locales/index';

@customElement('ki-catchment-info')
export default class KiCatchmentInfo extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
    }
    .station-name {
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }
    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }
    .label {
      color: #4a4a49;
    }
  `;

  static get properties() {
    return {
      catchment: { type: Object },
    };
  }

  constructor() {
    super();
    this.catchment = {};
  }

  render() {
    return html`<div class="">${this.catchment.catchment_name}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell label">${this.i18n.t('alarm_status')}</div>
          <div class="cell">${this.catchment.__tag}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('message_time')}</div>
          <div class="cell">${this.catchment.catchmentAlarmTimestamp}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('message')}</div>
          <div class="cell">${this.catchment.catchmentAlarmMessage || '-'}</div>
        </div>
      </div>`;
  }
}
