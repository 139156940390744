/* eslint-disable lit/no-invalid-html */
import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/no-extraneous-dependencies

import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { getCurrentApi } from '@kisters/wiski-web/api';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { customElement, property } from 'lit/decorators.js';

@customElement('ww-pdf-reports')
export default class WwPdfReports extends LitElement {
  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
  }

  data = [];

  @property({ type: String })
  path = '/documents';

  @property({ type: String })
  selectedDoc = '';

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);
    console.log(params.content);
    // Usually "documents" or "reports"
    this.path = params.options?.path;

    this.fetchData();
  }

  _getFullPath(subpath) {
    return new URL(
      `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.api.dataPath}${this.path}/${subpath}`,
    ).href;
  }

  async fetchData() {
    const res = await this.api._requestJson(`/${this.path}/index.json`);
    // Filter reports; only allow visible PDF
    this.data = res.data
      .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified))
      .map(doc => ({ label: doc.name.replace('.pdf', ''), value: doc.name }));
    if (this.data.length) {
      this.selectedDoc = `${this._getFullPath(this.data[0].value)}`;
    }
  }

  render() {
    // language=html
    return this.data && this.selectedDoc
      ? html`<div class="left-panel">
            <ui5-select
              @change="${e => {
                this.selectedDoc = `${this._getFullPath(
                  e.target.selectedOption.value,
                )}`;
              }}"
            >
              ${this.data.map(
                item =>
                  html`<ui5-option value="${item.value}"
                    >${item.label}</ui5-option
                  >`,
              )}
            </ui5-select>
          </div>
          <div class="right-panel">
            <iframe
              title="content"
              id="contentframe"
              src="./pdfjs/web/viewer.html?file=${this.selectedDoc}"
            ></iframe>
          </div>`
      : html`<div>Loading...</div>`;
  }
}
