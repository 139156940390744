import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import * as echarts from 'echarts/core';
import { LineChart, ScatterChart, BarChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import {
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  GraphicComponent,
  DataZoomComponent,
  MarkLineComponent,
  TitleComponent,
} from 'echarts/components';
// eslint-disable-next-line import/extensions
import dark from './dark.json';

import { getQueryObject } from '../../decorators/query';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  GraphicComponent,
  DataZoomComponent,
  MarkLineComponent,
  LineChart,
  BarChart,
  ScatterChart,
  SVGRenderer,
]);

echarts.registerTheme('dark', dark);

@customElement('ki-echart')
export default class KiEchart extends LitElement {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden;
    }
    .chart {
      height: 100%;
      width: 100%;
    }
  `;

  @property({ type: Object })
  options: any;

  @property({ type: Boolean })
  darkMode: boolean = false;

  @property({ type: Object, attribute: false })
  chart: any = false;

  get chartElement() {
    return getQueryObject(this, '#chart');
  }

  initChart() {
    this.chart = echarts.init(this.chartElement, this.darkMode ? 'dark' : '');
  }

  rerenderChart() {
    if (this.chart) {
      echarts.dispose(this.chart);
    }
    this.initChart();
    this.updated();
  }

  firstUpdated() {
    this.initChart();
  }

  updated() {
    // eslint-disable-next-line no-unused-expressions
    this.options && this.chart && this.chart.setOption(this.options);
  }

  updateTheme(isDark: boolean) {
    this.darkMode = isDark;
    this.rerenderChart();
  }

  resize() {
    this.chart.resize();
  }

  render() {
    return html` <div id="chart" class="chart"></div> `;
  }
}
