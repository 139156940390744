/* eslint-disable lit-a11y/mouse-events-have-key-events */
/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../ki-icon/ki-icon-btn';

const popupBeforeLeft = 13;

const getRelativePosAndPercentageFromMouseEventOnBar = e => {
  const targetwidth = e.target.clientWidth;
  const targetX = e.target.getBoundingClientRect().x;
  const mouseX = e.x;
  const pointedX = mouseX - targetX;
  const percentage = (pointedX * 100) / targetwidth;

  return { pointedX, percentage };
};

@customElement('ki-progress-bar')
export default class KiProgressBar extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      display: block;
    }
    .progress-bar-container {
      padding-top: 33px;
      width: 100%;
      height: 8px;
    }
    .progress-bar {
      height: 8px;
    }
    .left-border-rounded {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    .right-border-rounded {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
    .progress-bar-background {
      width: 100%;
      cursor: pointer;
      background-color: gray;
    }
    .progress-status-bar {
      pointer-events: none;
      position: relative;
      top: -8px;
      background-color: green;
    }
    .smooth-transition {
      -webkit-transition: width ease-in-out 0.7s;
      transition: width ease-in-out 0.7s;
    }
    .popup {
      pointer-events: none;
      position: absolute;
      display: block;
      box-sizing: border-box;
      padding: 3px;
      top: 0px;
      z-index: 999;
      border: 1px solid black;
      background: white;
      border-radius: 5px;
      margin-left: 250px;
    }
    .popup::before {
      pointer-events: none;
      top: 100%;
      left: ${popupBeforeLeft}px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: black;
      border-width: 0.5em;
      margin-left: -0.5em;
    }
  `;

  @property({ type: Object, attribute: false })
  currentPopupItem:
    | {
        label: string;
        untilPercentage: number;
      }
    | undefined;

  /**
   * Percentage steps on right/left arrow key.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  keyDownSteps: number = 5;

  /**
   * Width of the legend popup.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  popupWidth: number = 140;

  @property({ type: Number, attribute: false })
  popupMarginLeft: number = 0;

  /**
   * Items for showing on hover.
   *
   * @type {Array<{ label: string, untilPercentage: number, }>}
   */
  @property({ type: Array, attribute: true })
  popupItems: Array<{
    label: string;
    untilPercentage: number;
  }> = [];

  /**
   * Current status.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  statusPercentage: number = 0;

  @property({ type: Boolean, attribute: false })
  trackMovement: boolean = false;

  @property({ type: Boolean, attribute: false })
  forcePopup: boolean = false;

  clickBackground(e): void {
    this.trackMovement = false;
    this.updateStatus(e);
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('keydown', this.keyDown);
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    window.removeEventListener('mouseup', this.mouseUp);
    window.addEventListener('keydown', this.keyDown);
  }

  updated(_changedProperties: Map<string, any>): void {
    if (
      _changedProperties.has('popupItems') &&
      !_changedProperties.has('forcePopup')
    ) {
      this.mouseLeaveBar();
    }
  }

  updateStatus(e) {
    const { percentage } = getRelativePosAndPercentageFromMouseEventOnBar(e);

    this.statusPercentage = percentage;
    this.emitProgressChange();
  }

  emitProgressChange() {
    const options = {
      detail: { newPercentage: this.statusPercentage },
      bubbles: true,
      composed: true,
    };
    this.dispatchEvent(new CustomEvent('progressChange', options));
  }

  mouseOverBar(e): void {
    if (this.trackMovement) {
      this.updateStatus(e);
    }
    const { percentage, pointedX } =
      getRelativePosAndPercentageFromMouseEventOnBar(e);
    // -15 so that the arrow aligns with the mouse
    this.popupMarginLeft = pointedX - 15;
    this.setCurrentPopupItem(percentage);
    this.requestUpdate();
  }

  mouseDown(): void {
    this.trackMovement = true;
  }

  mouseUp(): void {
    this.trackMovement = false;
  }

  mouseLeaveBar(): void {
    this.currentPopupItem = undefined;
    this.requestUpdate();
  }

  keyDown = (e): void => {
    switch (e.key) {
      case 'ArrowLeft':
        this.statusPercentage -= this.keyDownSteps;
        this.emitProgressChange();
        break;
      case 'ArrowRight':
        this.statusPercentage += this.keyDownSteps;
        this.emitProgressChange();
        break;
      default:
        break;
    }
  };

  setCurrentPopupItem(percentage: number) {
    let newElement;
    this.popupItems
      .filter(item => item.untilPercentage >= percentage)
      .forEach(item => {
        if (!newElement || newElement.untilPercentage > item.untilPercentage) {
          newElement = item;
        }
      });
    this.currentPopupItem = newElement;
  }

  renderPopup() {
    if (this.forcePopup) {
      this.setCurrentPopupItem(this.statusPercentage);
      this.popupMarginLeft =
        (this.clientWidth * this.statusPercentage) / 100 - 15;
    }
    let marginDifference = 0;
    if (this.popupMarginLeft + this.popupWidth > this.clientWidth) {
      const oldMargin = this.popupMarginLeft;
      this.popupMarginLeft = this.clientWidth - this.popupWidth;
      marginDifference = oldMargin - this.popupMarginLeft;
    }
    return this.currentPopupItem !== undefined
      ? html`
          ${marginDifference !== 0
            ? html`
                <style>
                  .popup::before {
                    left: ${popupBeforeLeft + marginDifference}px !important;
                  }
                </style>
              `
            : ''}
          <div
            class="popup"
            style="margin-left: ${this.popupMarginLeft}px; width: ${this
              .popupWidth}px;"
          >
            ${this.currentPopupItem.label}
          </div>
        `
      : html``;
  }

  render() {
    return html`
      <div class="progress-bar-container" style="flex:1">
        <div
          id="progress-background"
          class="progress-bar left-border-rounded right-border-rounded progress-bar-background"
          @click="${this.clickBackground}"
          @mousedown="${this.mouseDown}"
          @mousemove="${this.mouseOverBar}"
          @mouseover="${this.mouseOverBar}"
          @mouseout="${this.mouseLeaveBar}"
        ></div>
        <div
          class="progress-bar progress-status-bar left-border-rounded ${this
            .statusPercentage >= 99
            ? 'right-border-rounded'
            : ''} ${this.trackMovement ? '' : 'smooth-transition'}"
          .style="width: ${this.statusPercentage}%"
        ></div>
        ${this.renderPopup()}
      </div>
    `;
  }
}
