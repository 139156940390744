/* eslint-disable class-methods-use-this */
import { customElement, property, query } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import Highcharts from 'highcharts/highstock';
import nodata from 'highcharts/modules/no-data-to-display';
import boost from 'highcharts/modules/boost';
import more from 'highcharts/highcharts-more';

@customElement('ww-graph-wrapper')
export default class WwGraphWrapper extends LitElement {
  highcharts = Highcharts;

  chart;

  @property({ type: Boolean })
  showLoading;

  @query('#chartcontainer')
  chartcontainer;

  @property({ type: Boolean })
  display;

  @property({ type: Array, attribute: false })
  series = [];

  constructor() {
    super();

    nodata(Highcharts);
    more(Highcharts);
    boost(Highcharts);
  }

  protected firstUpdated(): void {
    this.chart?.destroy();
    this.chart = new Highcharts.Chart({
      chart: {
        renderTo: this.chartcontainer,
        animation: false,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
        },
        {
          visible: false,
          min: 1,
          max: 2,
        },
      ],
      yAxis: {
        id: 'mainYAxis',
        title: {
          text: '',
        },
      },
      plotOptions: {
        series: {
          animation: false,
          label: {
            enabled: false,
          },
        },
      },
      series: [],
    });
  }

  updated(changedProperties) {
    super.update(changedProperties);
    if (this.series) {
      if (changedProperties.has('series')) {
        this.chart.series.forEach(ser => {
          ser.remove(false);
        });
        this.series.forEach(ser => {
          this.chart.addSeries(ser, false);
        });
        this.chart.update({});
      }
    }
  }

  render() {
    return html`<div
      style="width: 400px;height:220px;"
      id="chartcontainer"
    ></div>`;
  }
}
