import { customElement } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import StationDownloadLayer from './StationDownloadLayer';

@customElement('ki-station-download-control')
export default class KiStationDownloadControl extends _AddOn {
  static get properties() {
    return {
      label: { type: String },
      selectedStations: { type: Array },
    };
  }

  _create() {
    this.stationDownloadLayer = new StationDownloadLayer({
      name: 'Station download',
      displayInLayerControl: false,
      group: 'Information',
      label: this.label,
      selectedStations: this.selectedStations,
    });
    this.map.addControl(this.stationDownloadLayer);
    this.stationDownloadLayer.element.addEventListener(
      'downloadstationsmd',
      () => {
        this.dispatchEvent(
          new CustomEvent('downloadstationsmd', {
            bubbles: true,
          }),
        );
      },
    );
  }

  _tear() {
    this.map.removeControl(this.stationDownloadLayer);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
