import Control from 'ol/control/Control';
import ScaleLine from 'ol/control/ScaleLine';
import VisibleMixin from './VisibleMixin';
import MultiMousePosition from './MultiMousePosition';

/*
 * station may position and scale layers.
 * */
export default class MapPositionScaleLayer extends Control {
  constructor(options = {}) {
    options = { visible: true, ...options };
    options.element = document.createElement('div');
    options.element.setAttribute('id', 'scale-info');

    options.map.addControl(
      new (VisibleMixin(MultiMousePosition))({
        projection: 'EPSG:4326',
        className: 'custom-mouse-position',
        target: options.element,
        projections: options.projections,
        name: 'Coordinate',
        group: 'Information',
        displayInLayerControl: options.displayInLayerControl, // hide in mobile
      }),
    );

    options.map.addControl(
      new (VisibleMixin(ScaleLine))({
        units: 'metric',
        target: options.element,
        name: 'scale_info',
        group: 'Information',
        displayInLayerControl: options.displayInLayerControl, // hide in mobile
      }),
    );

    super(options);
    this.label = options.label;
    this.setVisible(options.visible);
    this.setProperties({
      name: options.name,
      displayInLayerControl: options.displayInLayerControl,
      group: options.group,
    });
  }

  set label(label) {
    this.element.label = label;
  }

  set options(options) {
    this.element.options = options;
  }

  setMap(map) {
    super.setMap(map);
  }

  // move a parent class
  setVisible(flag) {
    this._visible = flag;
    this.element.classList.toggle('hide', !flag);
  }

  getVisible() {
    return this._visible;
  }
}
