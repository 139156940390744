import { customElement } from 'lit/decorators.js';
import { flatten } from 'lodash-es';
import _AddOn from './_AddOn';

@customElement('ki-base-layers')
export default class KiBaseLayers extends _AddOn {
  static get properties() {
    return {
      layers: { type: Array },
      additionalLayers: { type: Array, initial: () => [] },
      defaultLayer: { type: String },
      zIndex: { type: String, attribute: 'z-index' },
    };
  }

  _create() {
    this.mapLayers = flatten(this.layers);
    this.map.getLayers().extend(this.mapLayers);
    this.mapLayers.forEach(l => {
      // TODO data update flow is not good
      // maybe layerControl should not change layer visibility directly.
      l.on('change:visible', e => {
        if (e.target.getVisible()) {
          this.dispatchEvent(
            new CustomEvent('changed', {
              detail: { value: e.target.get('label') },
              bubbles: true,
              composed: true,
            }),
          );
        }
      });
    });
  }

  _tear() {
    this.mapLayers.forEach(l => this.map.removeLayer(l));
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // TODO how to update.
    if (this.map && _changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
