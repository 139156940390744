import { getCurrentApi } from '@kisters/wiski-web/api';
import { css, html, LitElement } from 'lit';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { orderBy } from 'lodash-es';

export default class WwpHdoeHochwasserbericht extends LitElement {
  static styles = css`
    :host {
      display: block;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `;

  api = getCurrentApi();

  content = '';

  renderPDF() {
    const url = new URL(
      `${this.api.dataPath}/${this.content}`,
      window.location.origin,
    ).href;
    const fullurl = `./pdfjs/web/viewer.html?file=${url}`;
    return this.content
      ? html`<iframe title="content" src="${fullurl}"></iframe>`
      : html``;
  }

  async onAfterEnter() {
    this.fetchData();
  }

  render() {
    // language=html
    return this.renderPDF();
  }

  async fetchData() {
    this.api.getReportInfo().then(d => {
      const defaultBericht = d.all_listing.filter(
        item =>
          item.mimeType === 'application/pdf' &&
          item.name.includes(
            'documents/hochwasserbericht/default_Hochwasserbericht.pdf',
          ),
      );
      const hochwasserberichte = orderBy(
        d.all_listing.filter(
          item =>
            item.mimeType === 'application/pdf' &&
            item.name.includes('documents/hochwasserbericht/') &&
            !item.name.includes(
              'documents/hochwasserbericht/default_Hochwasserbericht',
            ),
        ),
        'lastModified',
        'desc',
      );
      if (hochwasserberichte.length) {
        this.content = hochwasserberichte[0].name;
      } else if (defaultBericht[0]) {
        this.content = defaultBericht[0].name;
      }
      this.requestUpdate();
    });
  }
}

customElements.define('wwp-hdoe-hochwasserbericht', WwpHdoeHochwasserbericht);
