import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import '@kisters/wcp-base/components/ki-icon/ki-icon-btn';
import { widgetType } from '../ki-widget/ki-widget.ts';
import { elementType, rowType } from './service/layout-service';
import { DragAreas, getAreaOfDrag } from './service/layout-helper';

const renderElementContent = element => {
  let elementWidgetType;
  switch (element.elementType) {
    case elementType.addNew:
      elementWidgetType = widgetType.addNew;
      break;
    case elementType.empty:
      elementWidgetType = widgetType.empty;
      break;
    case elementType.dropTarget:
    case elementType.default:
    case undefined:
    default:
      elementWidgetType = widgetType.default;
      break;
  }

  return html`
    <ki-widget
      type="${elementWidgetType}"
      configuration="${JSON.stringify(element.configuration)}"
    ></ki-widget>
  `;
};

const renderNewRowDropTarget = content => html`
  <div class="layout-element height-s">${content}</div>
`;

const patchRow = rowArray => {
  const currentLength = rowArray.length;
  const patchedArray = [...rowArray];
  const firstElement = rowArray[0];
  const addNewExists = firstElement.elementType === elementType.addNew;
  if (
    (firstElement.rowType === rowType.halfRow && currentLength < 2) ||
    (firstElement.rowType === rowType.thirdRow && currentLength < 3)
  ) {
    patchedArray.push({
      rowType: firstElement.rowType,
      elementType: addNewExists ? elementType.empty : elementType.addNew,
    });
    if (firstElement.rowType === rowType.thirdRow && currentLength < 2) {
      patchedArray.push({
        rowType: firstElement.rowType,
        elementType: elementType.empty,
      });
    }
  }
  return patchedArray;
};

const getFirstLayoutElement = elementArray => {
  let foundElement;

  elementArray.every(element => {
    foundElement = element;
    return element['data-row'] === undefined;
  });

  return foundElement;
};

@customElement('ki-dynamic-layout')
export default class KiDynamicLayout extends LitElement {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      display: block;
      overflow: auto;
    }
    .layout-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      -webkit-transform: translateZ(
        0
      ); /* is needed to scroll while dragging in chrome */
      gap: 20px;
    }
    .layout-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
      box-sizing: border-box;
    }
    .layout-element {
      min-width: 200px;
      border: 1px solid green;
      box-sizing: border-box;
      flex: 1;
      position: relative;
    }
    .height-s {
      height: 400px;
    }
    .height-l {
      height: 500px;
    }
    .not-displayed {
      display: none;
    }
    .hidden {
      visibility: hidden;
    }
    .close-button {
      position: absolute;
      margin-top: 5px;
      margin-right: 5px;
      top: 0;
      right: 0;
    }
    .append-button {
      position: absolute;
      margin-right: 5px;
      top: 45%;
      right: 0;
    }
  `;

  layout: {
    rowType: rowType;
    elementType?: elementType;
    configuration?: { text: string };
  }[][] = [];

  cachedLayout: {
    rowType: rowType;
    elementType?: elementType;
    configuration?: { text: string };
  }[][] = [];

  succesfullyDropped: boolean = false;

  validTarget: boolean = false;

  newRowAfter: number | undefined;

  lastDragArea: DragAreas | undefined;

  currentDraggedElement: {
    element: {
      rowType: rowType;
      elementType?: elementType;
      configuration?: {
        text: string;
      };
    };
    row: any;
    column: any;
  } | null = null;

  static get properties() {
    return {
      layout: { type: Array, attribute: false },
      cachedLayout: { type: Array, attribute: false },
      currentDraggedElement: { type: Object, attribute: false },
      succesfullyDropped: { type: Boolean, attribute: false },
      validTarget: { type: Boolean, attribute: false },
      newRowAfter: { type: Number, attribute: false },
    };
  }

  appendElement(row) {
    this.layout[row].forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.rowType += 1;
    });
    this.cacheCurrentLayout();
    this.rerenderItems();
  }

  cacheCurrentLayout() {
    this.cachedLayout = [];

    this.layout.forEach(rowArray => this.cachedLayout.push([...rowArray]));
  }

  // eslint-disable-next-line class-methods-use-this
  deleteElement(row: number, column: number) {
    if (this.layout[row].length > column) {
      if (this.layout[row].length === 1) {
        this.layout.splice(row, 1);
      } else {
        this.layout[row].splice(column, 1);
      }
    } else {
      this.layout[row].forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.rowType -= 1;
      });
    }
    this.cacheCurrentLayout();
    this.rerenderItems();
  }

  elementDragStart(event) {
    this.cacheCurrentLayout();
    this.succesfullyDropped = false;
    this.validTarget = false;

    const { target } = event;

    const draggedElementRow = target['data-row'];
    const draggedElementColumn = target['data-column'];

    const tempCurrentDraggedElement = {
      element: this.layout[draggedElementRow][draggedElementColumn],
      row: draggedElementRow,
      column: draggedElementColumn,
    };

    const currentRowType = tempCurrentDraggedElement.element.rowType;

    // this timeout is necessary. https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
    setTimeout(() => {
      this.currentDraggedElement = tempCurrentDraggedElement;
      this.removeItem(draggedElementRow, draggedElementColumn, currentRowType);
    }, 10);
  }

  switchTargetToExistingRow(event, targetDomElement, targetElementRow) {
    const targetElementColumn = targetDomElement['data-column'];
    const targetElement = this.layout[targetElementRow][targetElementColumn];
    const targetElementRowType = this.layout[targetElementRow][0].rowType;
    const targetElementType = targetElement?.elementType ?? elementType.default;

    if (targetElementType === elementType.dropTarget) {
      return;
    }

    const isFullRowTarget =
      targetElementRowType === rowType.fullRow &&
      this.layout[targetElementRow][0].elementType === elementType.addNew;
    if (
      (targetElementRowType !== rowType.thirdRow ||
        this.layout[targetElementRow].length >= 3) &&
      (targetElementRowType !== rowType.halfRow ||
        this.layout[targetElementRow].length >= 2) &&
      !isFullRowTarget
    ) {
      return;
    }

    if (!this.currentDraggedElement) {
      return;
    }

    const removeElements = isFullRowTarget ? 1 : 0;
    const targetColumn = targetElement
      ? targetElementColumn
      : this.layout[targetElementRow].length;
    const tempElement = {
      rowType: targetElementRowType,
      configuration: this.currentDraggedElement.element.configuration,
      elementType: elementType.dropTarget,
    };
    this.layout[targetElementRow].splice(
      targetColumn,
      removeElements,
      tempElement,
    );
    this.validTarget = true;
    event.preventDefault();
    this.requestUpdate();
  }

  switchTarget(event, dragArea: DragAreas) {
    if (!this.currentDraggedElement) {
      return;
    }

    this.removeOldElement();

    const targetDomElement = getFirstLayoutElement(event.composedPath());

    const targetElementRow = targetDomElement['data-row'];

    if (dragArea === DragAreas.Lower) {
      this.newRowAfter = targetElementRow;
      this.validTarget = true;
      event.preventDefault();
      this.requestUpdate();
    } else if (dragArea === DragAreas.Upper && targetElementRow > 0) {
      this.newRowAfter = targetElementRow - 1;
      this.validTarget = true;
      event.preventDefault();
      this.requestUpdate();
    } else {
      this.switchTargetToExistingRow(event, targetDomElement, targetElementRow);
    }
  }

  elementDragOver(event) {
    const currentDragArea = getAreaOfDrag(event);

    if (currentDragArea !== this.lastDragArea) {
      this.switchTarget(event, currentDragArea);
    } else {
      event.preventDefault();
    }

    this.lastDragArea = currentDragArea;
  }

  elementDrop() {
    if (this.validTarget) {
      this.succesfullyDropped = true;
    }
  }

  removeOldElement() {
    this.newRowAfter = undefined;
    this.restoreCache();
    if (this.currentDraggedElement) {
      const { row } = this.currentDraggedElement;
      const { column } = this.currentDraggedElement;
      const elementRowType = this.currentDraggedElement.element.rowType;
      this.removeItem(row, column, elementRowType);
      this.requestUpdate();
    }
    this.validTarget = false;
  }

  elementDragEnd() {
    if (this.newRowAfter !== undefined) {
      this.newRowDragEnd();
    }
    if (!this.succesfullyDropped) {
      this.restoreCache();
      this.requestUpdate();
    } else {
      this.removeEmptyRows();
      this.cacheCurrentLayout();
    }
    this.currentDraggedElement = null;
    this.rerenderItems();
  }

  newRowDragEnd() {
    if (
      this.succesfullyDropped &&
      this.newRowAfter !== undefined &&
      this.currentDraggedElement
    ) {
      this.currentDraggedElement.element.rowType = rowType.thirdRow;
      this.layout.splice(this.newRowAfter + 1, 0, [
        this.currentDraggedElement.element,
      ]);
    }
    this.newRowAfter = undefined;
  }

  restoreCache() {
    this.layout = [];

    this.cachedLayout.forEach(rowArray => this.layout.push([...rowArray]));
  }

  removeItem(row, column, currentRowType) {
    this.layout[row].splice(column, 1);
    if (this.layout[row].length === 0) {
      this.layout[row].push({
        rowType: currentRowType,
        elementType: elementType.addNew,
      });
    }
    this.requestUpdate();
  }

  removeEmptyRows() {
    this.layout.forEach((row, index) => {
      if (row.length === 0 || row[0].elementType === elementType.addNew) {
        this.layout.splice(index, 1);
      }
    });
  }

  rerenderItems() {
    const oldLayout = [...this.layout];
    this.layout = [];
    this.performUpdate();
    this.layout = oldLayout;
  }

  renderElement(element, rowIndex, columnIndex) {
    return html`
      <div
        .data-row="${rowIndex}"
        .data-column="${columnIndex}"
        .draggable="${element.elementType === undefined ||
        element.elementType === elementType.default}"
        @dragstart="${this.elementDragStart}"
        @dragover="${this.elementDragOver}"
        @drop="${this.elementDrop}"
        @dragend="${this.elementDragEnd}"
        class="layout-element ${element.rowType !== rowType.fullRow
          ? 'height-s'
          : 'height-l'}"
      >
        ${renderElementContent(element)}
        ${!this.currentDraggedElement
          ? html`
              <div class="close-button">
                <ki-icon-btn
                  icon="ki ki-trash"
                  class="toggle-btn"
                  @click="${() => this.deleteElement(rowIndex, columnIndex)}"
                ></ki-icon-btn>
              </div>
              ${(element.rowType === rowType.halfRow && columnIndex === 1) ||
              element.rowType === rowType.fullRow
                ? html`
                    <div class="append-button">
                      <ki-icon-btn
                        icon="ki ki-arrow-right"
                        class="toggle-btn"
                        @click="${() => this.appendElement(rowIndex)}"
                      ></ki-icon-btn>
                    </div>
                  `
                : html``}
            `
          : html``}
      </div>
    `;
  }

  renderRow(rowArray, rowIndex) {
    return html`
      <div
        class="layout-row${rowArray[0].elementType === elementType.addNew
          ? ' not-displayed'
          : ''}"
      >
        ${rowArray.map((element, index) =>
          this.renderElement(element, rowIndex, index),
        )}
      </div>
      <div
        class="layout-row${this.currentDraggedElement &&
        this.newRowAfter === rowIndex &&
        rowArray[0].elementType !== elementType.addNew
          ? ''
          : ' not-displayed'}"
        .data-row="${rowIndex}"
        @drop="${this.elementDrop}"
      >
        ${this.renderFirstNewRowDropTarget(rowIndex)}
        ${renderNewRowDropTarget('+')} ${renderNewRowDropTarget('+')}
      </div>
    `;
  }

  renderFirstNewRowDropTarget(rowIndex) {
    const content =
      this.newRowAfter !== undefined && this.newRowAfter === rowIndex
        ? renderElementContent(this.currentDraggedElement?.element)
        : '+';
    return html` ${renderNewRowDropTarget(content)} `;
  }

  render() {
    return html`
      <div class="layout-container">
        ${this.layout.map((rowArray, index) =>
          this.renderRow(patchRow(rowArray), index),
        )}
      </div>
    `;
  }
}
