export enum DragAreas {
  Upper,
  Lower,
  Center,
}

export const getAreaOfDrag = (e: {
  layerX: number;
  layerY: number;
  target: {
    clientWidth: number;
    clientHeight: number;
  };
}): DragAreas => {
  const thresholdFactor = 0.1;
  const minThreshold = e.target.clientHeight * thresholdFactor;
  const maxThreshold = e.target.clientHeight - minThreshold;

  if (e.layerY < minThreshold) {
    return DragAreas.Upper;
  }

  if (e.layerY > maxThreshold) {
    return DragAreas.Lower;
  }

  return DragAreas.Center;
};
