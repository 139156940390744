// eslint-disable-next-line max-classes-per-file
import { html } from 'lit';
import { getRoutes } from '@kisters/wcp-base/components/ki-app/services/ki-app-config-service';

import WwMapPopupBase from './ww-map-popup-base';

export default class WwMapPopupAuto extends WwMapPopupBase {
  // eslint-disable-next-line class-methods-use-this
  getGraph(station) {
    const routes = getRoutes();

    if (routes.length <= 0) {
      return [];
    }

    const stationDetailRoute = routes[0].children?.find
      ? routes[0].children.find(
          child => child?.component === 'wwp-station-detail',
        )
      : undefined;

    if (!stationDetailRoute) {
      return [];
    }

    const conf = stationDetailRoute.children.find(
      item =>
        item.component.includes('ki-wwp-graph') &&
        item.options.station_parameter === station.stationparameter_no,
    );

    if (!conf) {
      return html``;
    }

    const options = JSON.parse(JSON.stringify(conf.options));

    options.ranges = [options.ranges[0]];
    if (conf.component === 'ki-wwp-graph-precip') {
      return html`<ki-wwp-graph-precip
        miniGraph
        .options="${options}"
        stationId="${station.station_id}"
        style="width:360px;height:200px;"
      ></ki-wwp-graph-precip>`;
    }

    return html`<ki-wwp-graph
      miniGraph
      .options="${options}"
      stationId="${station.station_id}"
      style="width:360px;height:200px;"
    ></ki-wwp-graph>`;
  }
}

customElements.define('ww-map-popup-auto', WwMapPopupAuto);
