// TODO: store in config
const configServerUrl = 'http://localhost:3300';

export enum rowType {
  fullRow,
  halfRow,
  thirdRow,
}

export enum elementType {
  default,
  addNew,
  empty,
  dropTarget,
}

export const getLayout = async (
  uuid: string,
): Promise<{
  layout: {
    rowType: rowType;
    elementType?: elementType;
    configuration?: { text: string };
  }[][];
  name: string;
  description: string;
}> => {
  const response = await fetch(
    `${configServerUrl}/config/${encodeURIComponent(uuid)}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return response.json();
};

export const saveLayout = async (
  name: string,
  description: string,
  layout: {
    rowType: rowType;
    elementType?: elementType;
    configuration?: { text: string };
  }[][],
): Promise<string> => {
  const response = await fetch(`${configServerUrl}/config`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      name,
      description,
      layout,
    }),
  });
  return (await response.json()).uuid;
};

export const getExampleLayout = (): {
  rowType: rowType;
  elementType?: elementType;
  configuration?: Object;
}[][] => [
  [
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 1.1],
            type: 'line',
          },
        ],
      },
    },
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 1.2],
            type: 'line',
          },
        ],
      },
    },
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 1.3],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 2.1],
            type: 'line',
          },
        ],
      },
    },
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 3.1],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.thirdRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 3.1],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.halfRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
    {
      rowType: rowType.halfRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.halfRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.fullRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.fullRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.fullRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 8],
            type: 'line',
          },
        ],
      },
    },
  ],
  [
    {
      rowType: rowType.fullRow,
      configuration: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [1.5, 2.3, 2.24, 2.18, 1.35, 1.47, 2.6, 9],
            type: 'line',
          },
        ],
      },
    },
  ],
];
